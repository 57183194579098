import { sortByTimeStart } from '../../helpers/submission-reviews-helper';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { Review, ReviewDataDTO } from '../../interface/review';
import { Tab } from '../../interface/tab';
import { Option } from '../../interface/select-types';
import { SubmissionDetailsDTO } from '../../interface/submission-details-dto';
import { SubmissionIssueDTO } from '../../interface/submission-issue-dto';

interface SubmissionState {
  isReviewAlertActive: boolean;
  reviewAlertActiveTimeout: any;
  isTranscriptExtended: boolean;
  isMediaPlayerExtended: boolean;
  isSelectingContext: boolean;
  submissionReviews: SubmissionIssueDTO[];
  currentRecord: SubmissionDetailsDTO;
  enableReview: boolean;
  transcriptTabInfoMessage: string;
  reviews: Review[];
  currentTab: Tab;
  removedSubmissionReviews: SubmissionIssueDTO[];
  blockedInteraction: boolean;
}

const initialState: SubmissionState = {
  isReviewAlertActive: false,
  reviewAlertActiveTimeout: undefined,
  isTranscriptExtended: false,
  isMediaPlayerExtended: false,
  isSelectingContext: false,
  submissionReviews: [],
  currentRecord: {} as SubmissionDetailsDTO,
  enableReview: false,
  transcriptTabInfoMessage: '',
  reviews: [],
  currentTab: { id: 0, name: 'Summary', show: true },
  removedSubmissionReviews: [],
  blockedInteraction: false,
};

const submissionSlice: Slice = createSlice({
  name: 'submission',
  initialState: initialState,
  reducers: {
    setIsReviewAlertActive: (state, action: PayloadAction<boolean>) => {
      state.isReviewAlertActive = action.payload;
    },
    setReviewAlertTimeout: (state, action: PayloadAction<any>) => {
      state.reviewAlertActiveTimeout = action.payload;
    },
    setIsTranscriptExtended: (state, action: PayloadAction<boolean>) => {
      state.isTranscriptExtended = action.payload;
    },
    setIsMediaPlayerExtended: (state, action: PayloadAction<boolean>) => {
      state.isMediaPlayerExtended = action.payload;
    },
    setIsSelectingContext: (state, action: PayloadAction<boolean>) => {
      state.isSelectingContext = action.payload;
    },
    setSubmissionReviews: (state, action: PayloadAction<SubmissionIssueDTO[]>) => {
      // Sort the submissionReviews (Rule Violations array) based on the length of the issueContent property in descending order
      // to avoid creating spans on text that was not yet 'colored'
      const submissionReviews = [...action.payload];
      state.submissionReviews = submissionReviews.sort(sortByTimeStart);
    },
    setCurrentRecord: (state, action: PayloadAction<SubmissionDetailsDTO>) => {
      state.currentRecord = action.payload;
    },
    setEnableReview: (state, action: PayloadAction<boolean>) => {
      state.enableReview = action.payload;
    },
    setTranscriptTabInfoMessage: (state, action: PayloadAction<string>) => {
      state.transcriptTabInfoMessage = action.payload;
    },
    setReviews: (state, action: PayloadAction<ReviewDataDTO[]>) => {
      state.reviews = action.payload.map((reviewDataDTO: ReviewDataDTO) => {
        return {
          ...reviewDataDTO.review,
          reviewSheet: reviewDataDTO.reviewSheet,
        };
      });
    },
    pushReview: (state, action: PayloadAction<ReviewDataDTO>) => {
      state.reviews.push({
        ...action.payload.review,
        reviewSheet: action.payload.reviewSheet,
      });
    },
    changeCurrentTab: (state, action: PayloadAction<Tab>) => {
      if (action.payload.name) {
        state.currentTab = action.payload;
      }
    },
    setRemovedSubmissionReviews: (state, action: PayloadAction<SubmissionIssueDTO[]>) => {
      state.removedSubmissionReviews = [...action.payload];
    },
    setBlockedInteraction: (state, action: PayloadAction<boolean>) => {
      state.blockedInteraction = action.payload;
    },
    pushTranscriptVersion: (state: SubmissionState, action: PayloadAction<Option>) => {
      state.currentRecord?.transcriptVersions.unshift(action.payload);
    },
    updateSummaryDetails: (state: SubmissionState, action: PayloadAction<any>) => {
      const updatedCurrentRecord = { ...state.currentRecord };

      updatedCurrentRecord.workflowStatus = action.payload.workflowStatus;
      updatedCurrentRecord.assignedToName = action.payload.assignedToName;

      state.currentRecord = updatedCurrentRecord;
    },
  },
});

export const {
  setIsReviewAlertActive,
  setReviewAlertTimeout,
  setIsTranscriptExtended,
  setIsMediaPlayerExtended,
  setIsSelectingContext,
  setSubmissionReviews,
  setCurrentRecord,
  setEnableReview,
  setTranscriptTabInfoMessage,
  setReviews,
  pushReview,
  changeCurrentTab,
  setRemovedSubmissionReviews,
  setBlockedInteraction,
  pushTranscriptVersion,
  updateSummaryDetails,
} = submissionSlice.actions;

export default submissionSlice.reducer;
