import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { ReviewSheetTemplate } from '../../interface/review-sheet-template';

interface ReviewState {
  templates: ReviewSheetTemplate[];
}

const initialState: ReviewState = {
  templates: [],
};

const reviewSlice: Slice = createSlice({
  name: 'review',
  initialState: initialState,
  reducers: {
    setTemplates: (state: ReviewState, action: PayloadAction<ReviewSheetTemplate[]>) => {
      state.templates = action.payload;
    },
  },
});

export const { setTemplates } = reviewSlice.actions;

export default reviewSlice.reducer;
