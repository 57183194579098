import { ChangeEvent, DragEvent, ReactNode, useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { MdDownload, MdRefresh } from 'react-icons/md';
import {
  FaFileAlt,
  FaFileUpload,
  FaRegTrashAlt,
  FaFileImage,
  FaFileVideo,
  FaFilePdf,
  FaFileAudio,
  FaFileCsv,
  FaFileExcel,
  FaFileWord,
} from 'react-icons/fa';
import { FaFileZipper } from 'react-icons/fa6';
import { GoDotFill } from 'react-icons/go';
import { Tooltip } from 'react-tooltip';
import MultiStateSwitchComponent from '../../../components/ui/multi-state-switch';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { useAppSelector } from '../../../redux/hooks';
import { Attachment } from '../../../interface/attachment';
import attachmentService from '../../../resources/attachments/attachment.service';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { AttachmentDTO } from '../../../interface/attachment-dto';
import { FileExtended } from '../../../interface/file-extended';
import AlertComponent from '../../../components/ui/alert';

import './index.scss';
import LoaderComponent from '../../../components/ui/loader';
import { getMimeType, hasSubmissionError } from '../../../helpers/submission-helper';

interface AttachmentTabProps {
  isOpened: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const fileStatuses = { pending: 'pending', uploading: 'uploading', uploaded: 'uploaded', failed: 'failed' };

export default function AttachmentTab({ isOpened, onClose, onCancel }: AttachmentTabProps) {
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<FileExtended[]>([]);
  const [currentTab, setCurrentTab] = useState<string>('files');
  const [attachments, setAttachments] = useState<AttachmentDTO[]>();
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequestingAttachments, setIsRequestingAttachments] = useState<boolean>(false);

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { isTranscriptExtended } = useAppSelector(state => state.submission);

  useEffect(() => {
    fetchAttachments();
  }, []);

  useEffect(() => {
    fetchAttachments();
  }, [currentRecord.recordId]);

  useEffect(() => {
    if (isTranscriptExtended) {
      onClose();
    }
  });

  function changeTab(tab: string) {
    setCurrentTab(tab);
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.target.files;

    addFiles(files);
  }

  function handleDrop(event: DragEvent) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    addFiles(droppedFiles);
  }

  function addFiles(files: FileList | null) {
    setErrorMessage('');

    if (hasSubmissionError(currentRecord)) {
      setErrorMessage('Unable to attach files to a submission with error');
      return;
    }

    if (files && files.length > 0) {
      const newFiles = Array.from(files).map((file: File) => ({ file, progress: 0, status: fileStatuses.pending }));

      setFilesToBeUploaded(prevFiles => [...prevFiles, ...newFiles]);
    }
  }

  function uploadFile(fileExtended: FileExtended) {
    setErrorMessage('');

    const attachment: Attachment = {
      submissionId: currentRecord.submissionId,
      fileName: fileExtended.file.name,
      fileType: getMimeType(fileExtended.file.name),
      fileSize: fileExtended.file.size,
    };

    updateFileStatus(fileExtended, fileStatuses.uploading);

    attachmentService
      .signAttachment(attachment)
      .promise.then((res: any) => {
        const signedUrl: string = res.signedUrl;
        const attachmentId: number = res.attachmentId;
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('Content-Type', attachment.fileType);

        xhr.upload.onprogress = event => updateFileProgress(event, fileExtended);

        xhr.onload = () => {
          if (xhr.status === 200) {
            attachmentService.updateStatusToUploaded(attachmentId).promise.catch(error => console.error(error));
            updateFileStatus(fileExtended, fileStatuses.uploaded);
          } else {
            attachmentService.updateStatusToFailed(attachmentId).promise.catch(error => console.error(error));
            updateFileStatus(fileExtended, fileStatuses.failed);
          }

          if (allFilesAreUploaded()) {
            fetchAttachments();
            setSuccessMessage('Upload successful');
            setTimeout(() => {
              closeSuccessAlert();
            }, 10000);
          }
        };

        xhr.onerror = () => {
          attachmentService.updateStatusToFailed(attachmentId).promise.catch(error => console.error(error));
          updateFileStatus(fileExtended, fileStatuses.failed);
        };

        xhr.send(fileExtended.file);
      })
      .catch(error => {
        const errorMessage: string = error.response.data.message;

        if (errorMessage && errorMessage.includes('Attachment already exists')) {
          fileExtended.error = 'Duplicate';
          setErrorMessage('File(s) with duplicate status need to be renamed');
        } else {
          console.error(error);
        }

        updateFileStatus(fileExtended, fileStatuses.failed);
      });
  }

  function updateFileProgress(event: ProgressEvent<EventTarget>, fileExtended: FileExtended) {
    if (event.lengthComputable) {
      const percentComplete = Math.round((event.loaded / event.total) * 100);
      fileExtended.progress = percentComplete;

      updateFile(fileExtended);
    }
  }

  function updateFile(fileExtended: FileExtended) {
    if (filesToBeUploaded) {
      const filesToBeUploadedCopy = [...filesToBeUploaded];
      const fileIndex = filesToBeUploadedCopy.indexOf(fileExtended);

      if (fileIndex > -1) {
        filesToBeUploadedCopy[fileIndex] = fileExtended;
      }

      setFilesToBeUploaded(filesToBeUploadedCopy);
    }
  }

  function updateFileStatus(fileExtended: FileExtended, status: string) {
    fileExtended.status = status;
    updateFile(fileExtended);
  }

  function allFilesAreUploaded() {
    return filesToBeUploaded.every(fileExtended => fileExtended.status === fileStatuses.uploaded);
  }

  function someFileIsBeingUploaded() {
    return filesToBeUploaded.some(fileExtended => fileExtended.status === fileStatuses.uploading);
  }

  function uploadFiles(filesParam: FileExtended[]) {
    closeErrorAlert();

    if (filesParam.length > 0) {
      for (let index = 0; index < filesParam.length; index++) {
        const file = filesParam[index];

        if (file.status === fileStatuses.uploaded) {
          continue;
        }

        uploadFile(file);
      }
    }
  }

  function fetchAttachments() {
    if (isRequestingAttachments) {
      return;
    }
    setIsRequestingAttachments(true);

    attachmentService
      .getAttachments(currentRecord.submissionId)
      .promise.then((attachments: AttachmentDTO[]) => {
        setAttachments(attachments);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsRequestingAttachments(false);
      });
  }

  function formatDate(date: Date, format?: string) {
    const defaultFormat = 'MMMM Do YYYY, h:mm a';

    return moment(date).format(format ? format : defaultFormat);
  }

  function formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return '0 bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  function removeFile(fileExtended: FileExtended): void {
    if (filesToBeUploaded) {
      const filesToBeUploadedCopy = [...filesToBeUploaded];
      const fileIndex = filesToBeUploadedCopy.indexOf(fileExtended);

      if (fileIndex > -1) {
        filesToBeUploadedCopy.splice(fileIndex, 1);
      }

      setFilesToBeUploaded(filesToBeUploadedCopy);
    }
  }

  function cancelUpload() {
    setFilesToBeUploaded([]);
    onCancel();
  }

  function refreshUpload(fileExtended: FileExtended) {
    uploadFile(fileExtended);
  }

  function selectFileIcon(fileType: string): ReactNode {
    const iconMap: { [key: string]: ReactNode } = {
      image: <FaFileImage size={20} color="#5E677B" />,
      video: <FaFileVideo size={20} color="#5E677B" />,
      pdf: <FaFilePdf size={20} color="#5E677B" />,
      zip: <FaFileZipper size={20} color="#5E677B" />,
      audio: <FaFileAudio size={20} color="#5E677B" />,
      csv: <FaFileCsv size={20} color="#5E677B" />,
      spreadsheet: <FaFileExcel size={20} color="#5E677B" />,
      word: <FaFileWord size={20} color="#5E677B" />,
      default: <FaFileAlt size={20} color="#5E677B" />,
    };

    return iconMap[Object.keys(iconMap).find(key => fileType.includes(key)) || 'default'];
  }

  function formatFileName(fileName: string): string {
    const stringLimit = 45;

    if (fileName.length > stringLimit) {
      return fileName.substring(0, stringLimit).trim().concat('...');
    }
    return fileName;
  }

  function closeSuccessAlert() {
    setSuccessMessage('');
  }

  function closeErrorAlert() {
    setErrorMessage('');
  }

  function countFilesMissingUpload(): number {
    const count = filesToBeUploaded.filter((file: FileExtended) => file.status !== fileStatuses.uploaded).length;

    return count;
  }

  return (
    <div className="attachments-tab-container" hidden={!isOpened}>
      <div className="attachments-tab-header">
        <span className="attachments-tab-title">Attachments</span>
        <IoIosClose size={30} color="#5E677B" onClick={onClose} />
      </div>
      <div className="attachments-tab-body">
        <MultiStateSwitchComponent
          options={[
            { id: 'files', label: 'Files' },
            { id: 'upload', label: 'Upload' },
          ]}
          name="attach-switch"
          defaultOption={currentTab}
          onChange={changeTab}
        />
        {currentTab === 'upload' ? (
          <div className="attachments-tab-upload">
            {successMessage && (
              <AlertComponent text={successMessage} type="success" onClick={closeSuccessAlert} buttonText="X" />
            )}
            {errorMessage && (
              <AlertComponent text={errorMessage} type="danger" onClick={closeErrorAlert} buttonText="X" />
            )}
            <div className="upload-drop-zone" onDrop={handleDrop} onDragOver={event => event.preventDefault()}>
              <FaFileUpload size={24} color="#5E677B" />
              <span>
                Drag & drop your files here or &nbsp;
                <label htmlFor="file-input" className="upload-choose-file-button">
                  choose files
                </label>
                <input
                  className="upload-choose-file-input"
                  type="file"
                  id="file-input"
                  multiple
                  onChange={handleFileChange}
                />
                .
              </span>
            </div>
            <div className="tab-upload-files">
              {filesToBeUploaded.length > 0 && (
                <div className="tab-upload-files-title-container">
                  <span className="tab-upload-files-title">{`Files to upload (${countFilesMissingUpload()})`}</span>
                  <span onClick={() => setFilesToBeUploaded([])} className="tab-upload-files-action">
                    <em>remove all</em>
                  </span>
                </div>
              )}
              {filesToBeUploaded.length > 0 && (
                <div className="files-upload-cards">
                  {filesToBeUploaded.map((fileExtended: FileExtended, index: number) => (
                    <div className="files-upload-card" key={index}>
                      <div className="files-upload-card-body">
                        {selectFileIcon(fileExtended.file.type)}
                        <div className="files-upload-card-content">
                          <span
                            className="files-upload-card-title"
                            data-tooltip-id="file-name-tooltip"
                            data-tooltip-content={fileExtended.file.name.length > 45 ? fileExtended.file.name : ''}>
                            {formatFileName(fileExtended.file.name)}
                          </span>
                          <div className="files-upload-card-infos">
                            <span className="files-upload-card-info">{formatBytes(fileExtended.file.size, 1)}</span>
                            {fileExtended.status === fileStatuses.failed && (
                              <>
                                <GoDotFill size={6} color="#5E677B" />
                                <span className="files-upload-card-error">{`${
                                  fileExtended.error ? fileExtended.error : 'Upload failed'
                                }`}</span>
                              </>
                            )}
                            {fileExtended.status === fileStatuses.uploaded && (
                              <>
                                <GoDotFill size={6} color="#5E677B" />
                                <span className="files-upload-card-completed">Upload completed</span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="files-upload-card-actions">
                          {fileExtended.status !== fileStatuses.uploading && (
                            <FaRegTrashAlt size={18} color="#5E677B" onClick={() => removeFile(fileExtended)} />
                          )}
                          {fileExtended.status === fileStatuses.failed && (
                            <MdRefresh size={22} color="#5E677B" onClick={() => refreshUpload(fileExtended)} />
                          )}
                        </div>
                      </div>
                      {fileExtended.status === fileStatuses.uploading && (
                        <div className="progress-bar-track">
                          <div
                            id="myBar"
                            className="progress-bar"
                            style={{
                              width: `${fileExtended.progress || 0}%`,
                            }}></div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="tab-upload-actions">
              <Button onClick={() => cancelUpload()} className="tab-upload-button-cancel">
                Cancel
              </Button>
              <Button
                onClick={() => uploadFiles(filesToBeUploaded)}
                disabled={filesToBeUploaded.length === 0 || someFileIsBeingUploaded() || allFilesAreUploaded()}
                className="tab-upload-button-upload">
                Upload
              </Button>
            </div>
            <p className="tab-upload-notice">
              <em>Once uploaded, the attachment can no longer be deleted.</em>
            </p>
          </div>
        ) : (
          <div className="attachments-tab-files">
            {isRequestingAttachments ? (
              <LoaderComponent styles={{ margin: '0 auto', padding: '14px' }} />
            ) : attachments && attachments.length > 0 ? (
              <div className="files-cards">
                {attachments?.map((attachment: AttachmentDTO, index: number) => (
                  <div className="files-card" key={index}>
                    {selectFileIcon(attachment.fileType)}
                    <div className="files-card-content">
                      <span
                        data-tooltip-id="file-name-tooltip"
                        data-tooltip-content={attachment.fileName.length > 45 ? attachment.fileName : ''}
                        className="files-card-title">
                        {formatFileName(attachment.fileName)}
                      </span>
                      <div className="files-card-infos">
                        <span>{formatDate(attachment.createdAt, 'YYYY-MM-DD')}</span>
                        <GoDotFill size={6} color="#5E677B" />
                        <span>{attachment.createdBy}</span>
                        <GoDotFill size={6} color="#5E677B" />
                        <span>{formatBytes(attachment.fileSize, 1)}</span>
                      </div>
                    </div>
                    <div className="files-card-actions">
                      <a href={attachment.singedSourceUrl} target="_blank">
                        <MdDownload size={22} color="#5E677B" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-attachments">There are no attachments</p>
            )}
            <Button
              className="attachments-tab-files-refresh"
              onClick={fetchAttachments}
              disabled={isRequestingAttachments || hasSubmissionError(currentRecord)}>
              <MdRefresh size={22} color="#fff" />
              Refresh
            </Button>
          </div>
        )}
      </div>
      <Tooltip id="file-name-tooltip" place="top" />
    </div>
  );
}
