import './index.scss';
import { Paper, CircularProgress } from '@mui/material';
import { formatSourceDate } from '../../dashboard/utils';
import { marked } from 'marked';
import { useEffect, useState, useMemo } from 'react';
import { SummaryDetail } from '../../../interface/summary-detail';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { CiEdit } from 'react-icons/ci';
import { Tooltip } from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap';
import SelectComponent from '../../../components/ui/select-component';
import { Option } from '../../../interface/select-types';
import submissionService from '../../../resources/submission/submission.service';
import submissionOptionsService from '../../../resources/submission-options/submission-options.service';
import { WorkflowStatus } from '../../../interface/workflow-status';
import { Assignee } from '../../../interface/assignee';
import AlertComponent from '../../../components/ui/alert';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import { CiFloppyDisk } from 'react-icons/ci';
import { capitalizeWords } from '../../../utils/string-utils';
import { isSupportedMediaType, isSubmissionProcessing, hasSubmissionError } from '../../../helpers/submission-helper';

export default function SummaryTab() {
  const [showModal, setShowModal] = useState(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [allStatusOptions, setAllStatusOptions] = useState<Array<Option>>([]);
  const [selectedStatus, setSelectedStatus] = useState<Option>({ value: '', label: '' });
  const [allUsersOptions, setAllUsersOptions] = useState<Array<Option>>([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<Option>({ value: '', label: '' });
  const [updateDetailsErrorMessage, setUpdateDetailsErrorMessage] = useState<string>('');
  const [submissionDetails, setSubmissionDetails] = useState<Array<SummaryDetail>>([]);
  const [otherSubmissionDetails, setOtherSubmissionDetails] = useState<Array<SummaryDetail>>([]);

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const creationDate = formatSourceDate(currentRecord?.sourceDate);

  const dispatch = useAppDispatch();

  const fixedSubmissionDetails: Array<SummaryDetail> = [
    {
      title: 'Apply Code:',
      value: currentRecord.applyCode || '',
    },
    {
      title: 'Upload Date:',
      value: creationDate,
    },
    {
      title: 'Submitted by:',
      value: currentRecord.submitterName || currentRecord.submitterOktaId || '',
    },
    {
      title: 'Assigned to:',
      value: currentRecord.assignedToName || '',
    },
    {
      title: 'Status:',
      value: currentRecord.workflowStatus || '',
    },
    {
      title: 'Analyst Decision:',
      value: currentRecord.analystDecision || '',
    },
    {
      title: 'Media Type:',
      value: currentRecord.mediaType || '',
    },
  ];

  function renderMarkdown(markdownText: string): string {
    // Use marked library to convert Markdown to HTML
    return marked.parse(markdownText).toString();
  }

  useEffect(() => {
    fetchStatus();
    fetchAssignees();
    buildSubmissionDetails();
  }, []);

  useEffect(() => {
    if (isSupportedMediaType(currentRecord.sourceLocation)) {
      const summaryTextEl = document.getElementById('summary-text');
      const processedSummary = removeSectionByTitle(
        currentRecord?.transcriptSummary || 'No summary to show.',
        'Claims that may have compliance issues',
      );

      if (summaryTextEl) {
        try {
          summaryTextEl.innerHTML = renderMarkdown(processedSummary);
        } catch (error) {
          summaryTextEl.innerHTML = processedSummary;
        }
      }
    }
  }, [currentRecord?.transcriptSummary]);

  function fetchStatus() {
    submissionOptionsService.getAllStatus().promise.then((statuses: Array<WorkflowStatus>) => {
      const statusOptions: Array<Option> = statuses.map((status: WorkflowStatus) => ({
        value: status.id.toString(),
        label: status.status,
      }));

      const sortedStatusOptions = statusOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
      setAllStatusOptions(sortedStatusOptions);

      let currentStatus = statusOptions.find(
        (statusOption: Option) => statusOption.label === currentRecord.workflowStatus,
      );

      if (currentStatus) {
        setSelectedStatus(currentStatus);
      }
    });
  }

  function fetchAssignees() {
    submissionOptionsService.getAllAssignedsAvailable().promise.then((assignees: Array<Assignee>) => {
      const assigneeOptions: Array<Option> = assignees.map((assignee: Assignee) => ({
        value: assignee.id.toString(),
        label: assignee.name,
      }));

      const sortedAssigneeOptions = assigneeOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
      setAllUsersOptions(sortedAssigneeOptions);

      let currentAssignee = assigneeOptions.find(
        (assigneeOption: Option) => assigneeOption.label === currentRecord.assignedToName,
      );

      if (currentAssignee) {
        setSelectedAssignedTo(currentAssignee);
      }
    });
  }

  function buildSubmissionDetails() {
    setSubmissionDetails(fixedSubmissionDetails);

    // Define constants for property categories
    const hideProperties = ['Submitted By', 'GCS File Reference Number'];
    const thirdLevelProperties = ['Plan', 'Product', 'Prospect', 'General Business'];

    // Extract submission origin properties
    const submissionOriginProperties = currentRecord.submissionOriginProperties;

    // Determine third level values
    const thirdLevelValues = submissionOriginProperties
      .filter(item => thirdLevelProperties.includes(item.propertyName) && item.propertyValue === 'true')
      .map(item => item.propertyName);

    // Determine levels and format them
    const levels = submissionOriginProperties.filter(item => item.propertyName.includes('Level'));
    let levelsValues = levels.map(property => capitalizeWords(property.propertyValue));

    if (thirdLevelValues.length > 0) {
      levelsValues = levelsValues.concat(thirdLevelValues.join(', '));
    }

    const buildedLevels = levelsValues.join(' > ');

    // Separate questions and non-questions, and filter out hidden properties
    const questions = submissionOriginProperties.filter(item => item.propertyName.endsWith('?'));
    const nonQuestions = submissionOriginProperties.filter(
      item => !item.propertyName.endsWith('?') && !levels.includes(item),
    );
    const filteredNonQuestions = nonQuestions.filter(item => !hideProperties.includes(item.propertyName));

    // Concatenate non-questions with questions
    const sortedData = [...filteredNonQuestions, ...questions];

    // Build detailed summary data
    const otherSubmissionDetailsData: SummaryDetail[] = sortedData.map(item => ({
      title: item.propertyName.endsWith('?') ? item.propertyName : `${item.propertyName}:`,
      value: item.propertyValue,
    }));

    // Add levels data if available
    if (buildedLevels.length > 0) {
      otherSubmissionDetailsData.unshift({
        title: 'Curriculum Segment / Audience: ',
        value: buildedLevels,
      });
    }

    // Set the other submission details
    setOtherSubmissionDetails(otherSubmissionDetailsData);
  }

  function removeSectionByTitle(markdownText: string, sectionTitle: string): string {
    const sections = markdownText.split(/## /);
    const sectionIndex = sections.findIndex(section => section.startsWith(sectionTitle));

    if (sectionIndex !== -1) {
      sections.splice(sectionIndex, 1);
      const updatedMarkdown = sections.join('\n## ');

      return updatedMarkdown.trim() + '\n';
    }

    return markdownText;
  }

  function editDetails() {
    setShowModal(true);
  }

  function handleAssignedTo(option: Option) {
    setSelectedAssignedTo(option);
  }

  function handleStatus(option: Option) {
    setSelectedStatus(option);
  }

  const handleSubmmitModal = async (e: any) => {
    setUpdateDetailsErrorMessage('');

    if (isRequesting) {
      return;
    }

    setIsRequesting(true);

    submissionService
      .updateSubmissionDetails(currentRecord.submissionId, selectedAssignedTo.value, selectedStatus.value)
      .promise.then(() => {
        setShowModal(false);
        dispatch(
          submissionReducer.updateSummaryDetails({
            workflowStatus: selectedStatus.label,
            assignedToName: selectedAssignedTo.label,
          }),
        );

        const updatedSubmissionDetails = submissionDetails.map(detail => {
          if (detail.title === 'Assigned to:') {
            return {
              ...detail,
              value: selectedAssignedTo.label,
            };
          }
          if (detail.title === 'Status:') {
            return {
              ...detail,
              value: selectedStatus.label,
            };
          }
          return detail;
        });

        setSubmissionDetails(updatedSubmissionDetails);
      })
      .catch((error: Error) => {
        setUpdateDetailsErrorMessage("We couldn't update details, please try again later.");
        console.error(error.message);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  function closeEditDetailsAlert() {
    setUpdateDetailsErrorMessage('');
  }

  function subProcAlertMustBeDisplayed(): boolean {
    if (!isSupportedMediaType(currentRecord.sourceLocation)) {
      return false;
    }
    if (isSubmissionProcessing(currentRecord)) {
      return true;
    }

    return false;
  }

  return (
    <div className="summaryTab">
      {subProcAlertMustBeDisplayed() && (
        <AlertComponent
          type="info"
          text="Submission is being processed. Transcript and review will be available when processing is completed."
        />
      )}
      {hasSubmissionError(currentRecord) && (
        <AlertComponent
          type="danger"
          text={`This submission was submitted with an error: "${currentRecord.statusDetail}"`}
        />
      )}
      <div className="contentCards summary-row">
        <div className="paperSubmission">
          <Paper className="contentCard" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Submission Details</span>
              <CiEdit
                onClick={editDetails}
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Edit assigned and status"
              />
            </div>
            {currentRecord && (
              <div className="paperBody">
                {submissionDetails.map((submissionDetail: SummaryDetail, index) => (
                  <p className="submissionDetail" key={index}>
                    <span className="detailTitle">{submissionDetail.title}</span>
                    {submissionDetail.value}
                  </p>
                ))}
              </div>
            )}
          </Paper>
        </div>

        {isSupportedMediaType(currentRecord.sourceLocation) ? (
          <Paper className="contentCard paperSummary" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Summary</span>
            </div>
            {currentRecord && (
              <div id="summary-text" className="dataParagraph submissionSummary paperBody">
                {currentRecord.transcriptSummary}
              </div>
            )}
          </Paper>
        ) : (
          <Paper className="contentCard paperDownload" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Download</span>
            </div>
            <div>
              <div className="downloadDescription">
                Currently the review of media types other than Audio and Video is not available.
              </div>

              <a className="downloadBox" href={currentRecord.singedSourceUrl} target="_blank">
                <CiFloppyDisk color="#fff" />
                <div className="downloadText">Download</div>
              </a>
            </div>
          </Paper>
        )}

        <Tooltip id="my-tooltip" place="top" />
      </div>

      {currentRecord &&
        currentRecord.submissionOriginProperties &&
        currentRecord.submissionOriginProperties.length > 0 && (
          <Paper className="other-submission-details" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Other Details</span>
            </div>
            <div className="paperBody">
              <div className="submissionDetails">
                {otherSubmissionDetails.map((submissionDetail: SummaryDetail, index) => (
                  <p className="submissionDetail" key={index}>
                    <span className="detailTitle">{submissionDetail.title}</span>
                    {submissionDetail.value}
                  </p>
                ))}
              </div>
            </div>
          </Paper>
        )}

      <Modal
        className="summaryCustomModal"
        size="sm"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="EditDetailsModal">
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="EditDetailsModal">
            Edit Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          {updateDetailsErrorMessage && (
            <AlertComponent
              type="danger"
              text={updateDetailsErrorMessage}
              buttonText="X"
              onClick={closeEditDetailsAlert}
            />
          )}
          {isRequesting ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '32px',
              }}>
              <CircularProgress color="inherit" size={40} />
            </div>
          ) : (
            <div className="bodyContainer">
              <p>Select the new assigned and status:</p>
              <SelectComponent
                label="Assigned to"
                name="assigned-to"
                onChange={handleAssignedTo}
                isDisabled={false}
                options={allUsersOptions ?? []}
                selectedOption={selectedAssignedTo}
              />
              <SelectComponent
                label="Status"
                name="status"
                onChange={handleStatus}
                isDisabled={false}
                options={allStatusOptions ?? []}
                selectedOption={selectedStatus}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="button submitButton" onClick={handleSubmmitModal}>
            Confirm Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
