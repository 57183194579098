import { InputLabel } from '@mui/material';
import { Row } from 'react-bootstrap';
import MultiSelectDropdowComponent, { IOption } from '../../../components/ui/multi-select-dropdown';
import AsyncAutocompleteComponent from '../../../components/ui/async-autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Item } from '../../../components/ui/dropdown-btn';
import { getFiltersFromStorage, saveFiltersToStorage } from '../../../helpers/filters-local-storage';
import pipelineOutputService from '../../../resources/submission-options/submission-options.service';
import { Affiliate } from '../../../interface/affiliate';
import { capitalizeWords } from '../../../utils/string-utils';
import { WorkflowStatus } from '../../../interface/workflow-status';
import { Assignee } from '../../../interface/assignee';
import { Submitter } from '../../../interface/submitter';
import * as dashboardReducer from '../../../redux/reducers/dashboardReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ApprovedProvider } from '../../../interface/approved-provider';

interface Props {
  rowSearch: string;
  setRowSearch: (value: string) => void;
  selectedAffiliate: IOption;
  setSelectedAffiliate: (value: IOption) => void;
  selectedStatus: IOption;
  setSelectedStatus: (value: IOption) => void;
  selectedSubmitter: string | undefined;
  setSelectedSubmitter: (value: string) => void;
  selectedUser: string | undefined;
  setSelectedUser: (value: string) => void;
  selectedApprovedProvider: IOption;
  setSelectedApprovedProvider: (value: IOption) => void;
}

const DashboardFilters: React.FC<Props> = ({
  rowSearch,
  setRowSearch,
  selectedAffiliate,
  setSelectedAffiliate,
  selectedStatus,
  setSelectedStatus,
  selectedSubmitter,
  setSelectedSubmitter,
  selectedUser,
  setSelectedUser,
  selectedApprovedProvider,
  setSelectedApprovedProvider,
}) => {
  const [affiliates, setAffiliates] = useState<Array<Affiliate>>();
  const [workflowStatuses, setWorkflowStatuses] = useState<Array<WorkflowStatus>>();
  const [submitters, setSubmitters] = useState<Array<Submitter>>();
  const [approvedProviders, setApprovedProviders] = useState<Array<ApprovedProvider>>();

  const dispatch = useAppDispatch();
  const assignees: Array<Assignee> = useAppSelector(state => state.dashboard.assignees);

  // load all the dropdown options just once
  useEffect(() => {
    if (!affiliates) {
      fetchAffiliates();
    }
    if (!workflowStatuses) {
      fetchWorkflowStatus();
    }
    if (!submitters) {
      fetchSubmitters();
    }
    if (!assignees || assignees.length === 0) {
      fetchAssignees();
    }
    if (!approvedProviders || assignees.length === 0) {
      fetchApprovedProvider();
    }
  }, []);

  function fetchAffiliates() {
    pipelineOutputService
      .getAllAffiliates()
      .promise.then((affiliates: Array<Affiliate>) => {
        setAffiliates(affiliates);
      })
      .catch((error: Error) => {
        console.error(error.message);
      });
  }

  function fetchWorkflowStatus() {
    pipelineOutputService
      .getAllStatus()
      .promise.then((workflowStatuses: Array<WorkflowStatus>) => {
        setWorkflowStatuses(workflowStatuses);
      })
      .catch((error: Error) => {
        console.error(error.message);
      });
  }

  function fetchSubmitters() {
    pipelineOutputService
      .getAllSubmitters()
      .promise.then((submitters: Array<Submitter>) => {
        setSubmitters(submitters);
      })
      .catch((error: Error) => {
        console.error(error.message);
      });
  }

  function fetchAssignees() {
    pipelineOutputService
      .getAllAssignedsAvailable()
      .promise.then((assignees: Array<Assignee>) => {
        dispatch(dashboardReducer.setAssignees(assignees));
      })
      .catch((error: Error) => {
        console.error(error.message);
      });
  }

  function fetchApprovedProvider() {
    pipelineOutputService
      .getAllApprovedProvider()
      .promise.then((approvedProviders: Array<ApprovedProvider>) => {
        setApprovedProviders(approvedProviders);
      })
      .catch((error: Error) => {
        console.error(error.message);
      });
  }

  const allAffiliatesOptions = useMemo(() => {
    let options: IOption[] = [{ value: '', label: '' }];

    if (!affiliates) {
      return options;
    }

    options = options.concat(
      affiliates.map(affiliate => ({
        value: affiliate.affiliateCd.toString(),
        label: capitalizeWords(affiliate.affiliateName.toLowerCase()),
      })),
    );

    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [affiliates]);

  const allStatusOptions = useMemo(() => {
    let options: IOption[] = [{ value: '', label: '' }];

    if (!workflowStatuses) {
      return options;
    }

    options = options.concat(
      workflowStatuses.map((workflowStatus: WorkflowStatus) => ({
        value: workflowStatus.status,
        label: workflowStatus.status,
      })),
    );

    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [workflowStatuses]);

  const allSubmittersOptions = useMemo<Item[]>(() => {
    if (!submitters) {
      return [] as Item[];
    }

    const submitter = submitters.map((submitter: Submitter) => ({
      id: submitter.name,
      label: submitter.name,
      thirdLabel: submitter.oktaId,
    })) as Item[];

    return submitter;
  }, [submitters]);

  const allUsersOptions = useMemo<Item[]>(() => {
    if (!assignees) {
      return [] as Item[];
    }

    const assignedTo = assignees.map((assignee: Assignee) => ({
      id: assignee.name,
      label: assignee.name,
      thirdLabel: assignee.oktaId,
    })) as Item[];

    return assignedTo;
  }, [assignees]);

  const allApprovedProviders = useMemo(() => {
    let options: IOption[] = [{ value: '', label: '' }];

    if (!approvedProviders) {
      return options;
    }

    options = options.concat(
      approvedProviders.map((approvedProvider: ApprovedProvider) => ({
        value: approvedProvider.fullName,
        label: approvedProvider.fullName,
      })),
    );

    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [approvedProviders]);

  const handleAffiliateChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedAffiliate = event.target.value;
      const selectedOption = allAffiliatesOptions.find(option => option.value === selectedAffiliate);
      setSelectedAffiliate(selectedOption!);
      saveFiltersToStorage({ ...getFiltersFromStorage(), affiliate: selectedOption! });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [affiliates],
  );

  const handleStatusChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedStatus = event.target.value;
      const selectedOption = allStatusOptions.find(option => option.value === selectedStatus);
      setSelectedStatus(selectedOption!);
      saveFiltersToStorage({ ...getFiltersFromStorage(), status: selectedOption! });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflowStatuses],
  );

  const handleSubmitterChange = useCallback(
    (selectedSubmitter: string) => {
      setSelectedSubmitter(selectedSubmitter);
      saveFiltersToStorage({ ...getFiltersFromStorage(), selectedSubmitter });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitters],
  );

  const handleSelectedUserChange = useCallback(
    (selectedUser: string) => {
      setSelectedUser(selectedUser);
      saveFiltersToStorage({ ...getFiltersFromStorage(), selectedUser });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignees],
  );

  const handleApprovedProviderChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedApprovedProvider = event.target.value;
      const selectedOption = allApprovedProviders?.find(option => option.value === selectedApprovedProvider);
      setSelectedApprovedProvider(selectedOption!);
      saveFiltersToStorage({ ...getFiltersFromStorage(), approvedProvider: selectedOption! });
    },
    [approvedProviders],
  );

  const selectedUserItem = useMemo(
    () => (selectedUser ? allUsersOptions.find(it => it.id === selectedUser) ?? null : null),
    [selectedUser, allUsersOptions],
  );

  const selectedSubmitterItem = useMemo(
    () => (selectedSubmitter ? allSubmittersOptions.find(it => it.id === selectedSubmitter) ?? null : null),
    [selectedSubmitter, allSubmittersOptions],
  );

  const handleUserSearch = useCallback(
    (usernameOrEmail: string) => {
      return Promise.resolve(
        usernameOrEmail !== ''
          ? allUsersOptions.filter(
              userOption =>
                userOption.label.toLowerCase().startsWith(usernameOrEmail.toLowerCase()) ||
                userOption.thirdLabel?.toLowerCase()?.startsWith(usernameOrEmail.toLowerCase()),
            )
          : allUsersOptions,
      );
    },
    [allUsersOptions],
  );

  const handleSubmitterSearch = useCallback(
    (submitter: string) => {
      return Promise.resolve(
        submitter !== ''
          ? allSubmittersOptions.filter(submitterOption =>
              submitterOption.label.toLowerCase().startsWith(submitter.toLowerCase()),
            )
          : allSubmittersOptions,
      );
    },
    [allSubmittersOptions],
  );

  return (
    <div className="flex options">
      <Row className="id-search">
        <InputLabel className="input-label-search">
          <b>Search:</b>
        </InputLabel>
        <input
          value={rowSearch}
          placeholder="Enter a full or partial Apply Code or any text"
          onChange={event => {
            setRowSearch(event.target.value);
            saveFiltersToStorage({ ...getFiltersFromStorage(), search: event.target.value });
          }}
        />
      </Row>

      <Row className="id-filter">
        <InputLabel className="input-label">
          <b>Affiliate:</b>
        </InputLabel>
        <MultiSelectDropdowComponent
          options={allAffiliatesOptions}
          onChange={handleAffiliateChange}
          value={selectedAffiliate}
        />
      </Row>

      <Row className="id-filter">
        <InputLabel className="input-label">
          <b>Status:</b>
        </InputLabel>
        <MultiSelectDropdowComponent options={allStatusOptions} onChange={handleStatusChange} value={selectedStatus} />
      </Row>

      <Row className="id-filter">
        <InputLabel className="input-label">
          <b>Approved Provider:</b>
        </InputLabel>
        <MultiSelectDropdowComponent
          options={allApprovedProviders}
          onChange={handleApprovedProviderChange}
          value={selectedApprovedProvider}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Submitted By:"
          placeholder="Search submitter"
          styleType="filter"
          items={allSubmittersOptions}
          search={handleSubmitterSearch}
          selectedValue={selectedSubmitterItem}
          selectValue={item => handleSubmitterChange(item?.label as string)}
        />
      </Row>

      <Row className="id-filter">
        <AsyncAutocompleteComponent
          id="user-async-autocomplete"
          label="Assigned To:"
          placeholder="Search User ID or email"
          styleType="filter"
          items={allUsersOptions}
          search={handleUserSearch}
          selectedValue={selectedUserItem}
          selectValue={item => handleSelectedUserChange(item?.label as string)}
        />
      </Row>
    </div>
  );
};

export default DashboardFilters;
