export function capitalizeFirstLetter(string: any) {
  if (typeof string !== 'string' || string.length === 0) {
    // Handle invalid input, return an empty string, or throw an error as needed.
    return string; // Or return an empty string: return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function capitalizeWords(str: string): string {
  let words = str.split(' ');

  // Capitalize the first letter of each word
  let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words back into a single string
  let capitalizedStr = capitalizedWords.join(' ');

  return capitalizedStr;
}
