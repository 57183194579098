import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { Affiliate } from '../../interface/affiliate';
import { WorkflowStatus } from '../../interface/workflow-status';
import { Submitter } from '../../interface/submitter';
import { Assignee } from '../../interface/assignee';
import { ApprovedProvider } from '../../interface/approved-provider';

const SUBMISSION_OPTIONS_ENDPOINT = '/submission-options';

class SubmissionOptionsService {
  public getAllAffiliates(): WithAbortFn<Promise<Array<Affiliate>>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`${SUBMISSION_OPTIONS_ENDPOINT}/available-affiliates`, {
          cancelToken: source.token,
        })
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getAllStatus(): WithAbortFn<Promise<Array<WorkflowStatus>>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`${SUBMISSION_OPTIONS_ENDPOINT}/all-status`, {
          cancelToken: source.token,
        })
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getAllSubmitters(): WithAbortFn<Promise<Array<Submitter>>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`${SUBMISSION_OPTIONS_ENDPOINT}/all-submitters`, {
          cancelToken: source.token,
        })
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getAllAssignedsAvailable(): WithAbortFn<Promise<Array<Assignee>>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`${SUBMISSION_OPTIONS_ENDPOINT}/all-assigned`, {
          cancelToken: source.token,
        })
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getAllApprovedProvider(): WithAbortFn<Promise<Array<ApprovedProvider>>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`${SUBMISSION_OPTIONS_ENDPOINT}/all-approved-provider`, {
          cancelToken: source.token,
        })
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const submissionOptionsService = new SubmissionOptionsService();

export default submissionOptionsService;
