import './index.scss';
import { useEffect, useRef, useState } from 'react';
import { Paper, CircularProgress } from '@mui/material';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as utils from '../utils';

import { isValidEmail } from '../utils';
import useAuth from '../../../resources/auth/auth-hook';
import JoditEditor from 'jodit-react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import useViolationRule from '../../../resources/violation-rule/violation-rule-hook';
import { stringTimestamp, findIssueStartTime } from '../utils';
import parse from 'html-react-parser';

import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { MdOutlineFileCopy } from 'react-icons/md';
import { MdOutlinePictureAsPdf } from 'react-icons/md';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { IoDocumentTextOutline } from 'react-icons/io5';
import { CgDanger } from 'react-icons/cg';
import { CgCheckO } from 'react-icons/cg';

import reviewSheetService from '../../../resources/review-sheet/review-sheet.service';
import { pushReview, setEnableReview } from '../../../redux/reducers/submissionReducer';
import { Review, ReviewDataDTO } from '../../../interface/review';
import { getLastReview, hasTheReviewBeenSent } from '../../../helpers/reviews-helper';
import LoadingScreen from '../../loading';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { ReviewIssueViolationRule } from '../../../interface/review-issue-violation-rule';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import * as reviewReducer from '../../../redux/reducers/reviewReducer';
import { ISegment } from '../../../interface/segment';
import { ReviewSheetTemplate } from '../../../interface/review-sheet-template';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 4px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3478f6',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3478f6',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#3478f6',
  }),
  '& .QontoStepIcon-completedIcon': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: '2px solid #3478f6',
    zIndex: '99',
  },
  '& .QontoStepIcon-circle': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: '2px solid #eaeaf0',
    zIndex: '99',
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <div className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const steps = ['1. Select Template', '2. Fill in Information', '3. Preview & Submit'];

export default function ReviewTab() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { enableReview } = useAppSelector(state => state.submission);
  const { violationRules, fetchViolationRules } = useViolationRule();

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewSheetTemplate>();
  const [isValidEmailState, setValidEmailState] = useState<boolean>(true);
  const [isValidEmailCcState, setValidEmailCcState] = useState<boolean>(true);

  const [showApLegalEntityName, setShowApLegalEntityName] = useState(false);
  const [showApplyCode, setShowApplyCode] = useState(false);
  const [showBsmStatus, setShowBsmStatus] = useState(false);
  const [showTitleEnglish, setShowTitleEnglish] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [showSpeakerFullName, setShowSpeakerFullName] = useState(false);

  const [apLegalEntityName, setApLegalEntityName] = useState(
    currentRecord.approvedProviderName || currentRecord.submitterName,
  );
  const [apContactName, setApContactName] = useState(currentRecord.submitterName || '');
  const [applyCode, setApplyCode] = useState(currentRecord.applyCode || '');
  const [bsmStatus, setBsmStatus] = useState(currentRecord.workflowStatus || '');
  const [englishTitle, setTitleEnglish] = useState(currentRecord.englishTitle || '');
  const [signature, setSignature] = useState(user?.name || '');
  const [speaker, setSpeaker] = useState(currentRecord.speaker || '');

  const [email, setEmail] = useState('');
  const [emailCc, setEmailCc] = useState('');

  const [emailSent, setEmailSent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const { submissionReviews } = useAppSelector(state => state.submission);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const mergedSegments: ISegment[] = useAppSelector(state => state.transcript.mergedSegments);
  const templates: ReviewSheetTemplate[] = useAppSelector(state => state.review.templates);
  const lastReview = getLastReview(reviews);
  const dispatch = useAppDispatch();

  const [timestampString, setTimestampString] = useState(stringTimestamp(mergedSegments || null));

  // Fetch violation rules just once
  useEffect(() => {
    if (!violationRules.data && currentRecord) {
      fetchViolationRules();

      if (!mergedSegments || mergedSegments.length === 0) {
        processSegments();
      }

      setTimestampString(stringTimestamp(mergedSegments));
    }
  }, []);

  // Fetch review sheet templates
  useEffect(() => {
    if (templates.length === 0) {
      fetchReviewSheetTemplates();
    }
  }, []);

  useEffect(() => {
    setDefaultTemplate(templates);
  }, [templates]);

  useEffect(() => {
    if (email) setValidEmailState(isValidEmail(email));
  }, [email]);

  useEffect(() => {
    if (emailCc) setValidEmailCcState(isValidEmail(emailCc));
  }, [emailCc]);

  useEffect(() => {
    if (activeStep === 1 && selectedTemplate) {
      // Step 2, show/hide the necessary input fields
      setShowApLegalEntityName(selectedTemplate.content.includes('$ap_legal_entity_name'));
      setShowApplyCode(selectedTemplate.content.includes('$apply_code'));
      setShowBsmStatus(selectedTemplate.content.includes('$status'));
      setShowTitleEnglish(selectedTemplate.content.includes('$title'));
      setShowSignature(selectedTemplate.content.includes('$signature'));
      setShowSpeakerFullName(selectedTemplate.content.includes('$speakers'));
    } else if (activeStep === 2 && selectedTemplate) {
      // Step 3, replace the placeholders on the selected template
      const formattedCurrentDate = moment().format('MMMM Do, YYYY');
      let ruleViolations = '';

      if (selectedTemplate.content.includes('$rule_violations')) {
        ruleViolations = buildRuleViolationsSection();
      }

      setContent(
        selectedTemplate.content
          .replaceAll('$date', formattedCurrentDate)
          .replaceAll('$ap_legal_entity_name', apLegalEntityName)
          .replaceAll('$ap_contact_name', apContactName)
          .replaceAll('$apply_code', applyCode)
          .replaceAll('$status', bsmStatus)
          .replaceAll('$title', englishTitle)
          .replaceAll('$speakers', speaker)
          .replaceAll('$signature', signature)
          .replaceAll('$rule_violations', ruleViolations) || '',
      );
    }
  }, [activeStep, selectedTemplate]);

  function processSegments() {
    let segments = currentRecord.segments;

    if (typeof segments === 'string') {
      segments = JSON.parse(segments);
    }

    const merge = utils.mergeSegmentsAndTranscript(segments);
    dispatch(transcriptReducer.setMergedTranscript(merge.transcript));
    dispatch(transcriptReducer.setMergedSegments(merge.mergedSegments));
  }

  function fetchReviewSheetTemplates() {
    setIsLoading(true);

    return reviewSheetService
      .getTemplates()
      .promise.then((templates: ReviewSheetTemplate[]) => {
        dispatch(reviewReducer.setTemplates(templates));
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function setDefaultTemplate(templates: ReviewSheetTemplate[]) {
    // Default template if there are any RV.
    let hasNoIssues = true;
    let template = templates.find(
      (currentTemplate: ReviewSheetTemplate) => currentTemplate.name === 'Reviews Template',
    );

    submissionReviews?.forEach((submissionReview: SubmissionIssueDTO) => {
      if (submissionReview.reviewIssueViolationRule.some((issue: ReviewIssueViolationRule) => issue.userReviewStatus)) {
        hasNoIssues = false;
        return;
      }
    });

    // No RV, set the Authorization template as default.
    if (hasNoIssues) {
      template = templates.find((currentTemplate: ReviewSheetTemplate) => currentTemplate.name === 'Authorization');
    }

    setSelectedTemplate(template);
  }

  function buildRuleViolationsSection() {
    type IssueSection = {
      timeStart: number;
      finalText: string;
    };

    const issuesSection: IssueSection[] = [];
    const filteredSubmissionReviews = filterByUserReviewStatus(submissionReviews || []);
    const submissionReviewsGroupedByIssueContext = groupSubmissionReviewsByContext(filteredSubmissionReviews);
    submissionReviewsGroupedByIssueContext?.forEach(sr => {
      const timeStart = sr.reviewIssue.timeStart
        ? sr.reviewIssue.timeStart
        : findIssueStartTime(timestampString, currentRecord.transcript || '', sr.reviewIssue.issueContent).startTime;

      let issues = `<br>${moment.utc(timeStart * 1000).format('mm:ss')} - `;

      issues += processIssueContent(sr);

      // Adds the description section
      let finalText = '<div><br><span style="color: red;"> ';
      const descriptions = new Set();

      sr.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule) => {
        const ruleViolation = violationRules?.data?.find(rule => rule.id === rivr.violationRuleId);

        descriptions.add(ruleViolation?.errorDescription || ruleViolation?.name);
      });

      Array.from(descriptions).forEach((description, i) => {
        // error description
        finalText += `${description}`;

        if (i !== descriptions.size - 1) {
          finalText += '<br>';
        }
      });

      finalText += '</span>';

      // description
      finalText += issues;

      // comments
      const amountOfComments: number = sr.reviewIssueViolationRule.filter(
        (rivr: ReviewIssueViolationRule) => rivr.description,
      ).length;

      if (amountOfComments === 1) {
        finalText += '<br><strong>Comment: ';
      } else if (amountOfComments > 1) {
        finalText += '<br><strong>Comments: ';
      }

      sr.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule) => {
        if (rivr.description) {
          if (amountOfComments === 1) {
            finalText += `${rivr.description.replaceAll('double_quotes', '"')}`;
          } else {
            finalText += `${rivr.description.replaceAll('double_quotes', '"')} <br>`;
          }
        }
      });

      if (amountOfComments > 0) {
        finalText += '</strong>';
      }

      finalText += '</div>';

      issuesSection.push({
        timeStart,
        finalText: finalText,
      });
    });

    // Sort issuesSection based on timeStart
    issuesSection.sort((a, b) => (a.timeStart > b.timeStart ? 1 : -1));

    // Join each finalText in sorted issuesSection and return it
    const fullText = issuesSection.map(section => section.finalText).join('') + '<br>';

    return fullText;
  }

  function filterByUserReviewStatus(submissionReviews: SubmissionIssueDTO[]): SubmissionIssueDTO[] {
    const filteredSubmissionReviews: SubmissionIssueDTO[] = [];

    submissionReviews?.forEach(sr => {
      const issues = sr.reviewIssueViolationRule.filter(
        (rivr: ReviewIssueViolationRule) => rivr.userReviewStatus !== false,
      );

      if (issues.length > 0) {
        filteredSubmissionReviews.push({ ...sr, reviewIssueViolationRule: issues });
      }
    });

    return filteredSubmissionReviews;
  }

  function groupSubmissionReviewsByContext(submissionReviews: SubmissionIssueDTO[]): SubmissionIssueDTO[] {
    return submissionReviews?.reduce((acc: SubmissionIssueDTO[], obj) => {
      const existingItemIndex = acc.findIndex(
        item =>
          obj.reviewIssue.issueContext &&
          item.reviewIssue.issueContext &&
          item.reviewIssue.issueContext.trim() === obj.reviewIssue.issueContext.trim(),
      );
      const existingTimeStart = acc.find(item => item.reviewIssue.issueContext === obj.reviewIssue.issueContext)
        ?.reviewIssue.timeStart;

      if (existingItemIndex !== -1 && obj.reviewIssue.issueContext !== null) {
        const accIssueContent = acc[existingItemIndex].reviewIssue.issueContent;
        const accIssues = acc[existingItemIndex].reviewIssue.issues;
        const objIssueContent = obj.reviewIssue.issueContent;
        const objIssues = obj.reviewIssue.issues;

        if (accIssueContent !== '') {
          acc[existingItemIndex].reviewIssue.issueContent = `${accIssueContent}|||${objIssueContent}`;
        }
        acc[existingItemIndex].reviewIssueViolationRule.push(...obj.reviewIssueViolationRule);
        acc[existingItemIndex].reviewIssue.issues = accIssues.concat(objIssues);

        if (
          existingTimeStart !== undefined &&
          obj.reviewIssue.timeStart !== undefined &&
          obj.reviewIssue.timeStart < existingTimeStart
        ) {
          acc[existingItemIndex].reviewIssue.timeStart = obj.reviewIssue.timeStart;
          acc[existingItemIndex].reviewIssue.timeEnd = obj.reviewIssue.timeEnd;
        }
      } else {
        acc.push({
          reviewIssue: { ...obj.reviewIssue },
          reviewIssueViolationRule: [...obj.reviewIssueViolationRule],
        });
      }
      return acc;
    }, []);
  }

  function processIssueContent(sr: any): string {
    let issues = '';

    if (sr.reviewIssue.issueContext) {
      if (sr.reviewIssue.issueContext.includes("className='description'")) {
        issues += sr.reviewIssue.issueContext.replace('<span', `<span style="background: #FFFF00;"`);
      } else {
        const splitedContent = sr.reviewIssue.issueContent.split('|||');

        if (splitedContent.length > 1) {
          let newIssueContext = sr.reviewIssue.issueContext;
          splitedContent.forEach((content: string) => {
            if (content) {
              newIssueContext = utils.replaceContextWithHighlight(newIssueContext, content);
            }
          });
          issues += newIssueContext;
        } else {
          issues += utils.processHighlightedIssues(sr);
        }
      }
    } else {
      issues += utils.highlightWithYellowBackground(sr.reviewIssue.issueContent);
    }

    return issues;
  }

  // Function to check if any required fields are empty
  const isNextButtonDisabled = () => {
    return (
      !apLegalEntityName || !applyCode || !bsmStatus || !englishTitle || !signature || !email || !isValidEmailState
    );
  };

  const handleTemplateClick = (template: ReviewSheetTemplate | Item | null | undefined) => {
    if (!template) {
      return;
    }

    const templateFound = templates.find((currentTemplate: ReviewSheetTemplate) => currentTemplate.id === template.id);

    setSelectedTemplate(templateFound);
  };

  function handleCopy() {
    const textContent = document.querySelector('.jodit-wysiwyg')?.textContent;
    navigator.clipboard.writeText(textContent || '');
  }

  function handlePdf() {
    const element = document.querySelector('.jodit-wysiwyg') as HTMLElement;

    if (element) {
      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4'); // portrait, millimeters, A4 size
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('review-sheet');
      });
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  function handleModal() {
    setIsRequesting(true);
    setShowModal(true);

    reviewSheetService
      .newReviewSheet(
        currentRecord.submissionId,
        selectedTemplate!.id,
        content,
        apContactName,
        applyCode,
        englishTitle,
        signature,
        email,
        emailCc,
      )
      .promise.then((reviewDataDTO: ReviewDataDTO) => {
        setEmailSent(true);
        dispatch(pushReview(reviewDataDTO));
        dispatch(setEnableReview(false));
        setActiveStep(0);
      })
      .catch((err: any) => {
        setEmailSent(false);
        console.error(err);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleSubmmitModal = async (e: any) => {
    setShowModal(false);
    handleModal();
  };

  return isLoading ? (
    <LoadingScreen styles={{ height: '650px' }} />
  ) : (
    <div className="reviewTab contentCards">
      <Paper className="contentCard paperReview" variant="outlined">
        <div className="paper-title">
          <span className="paperText">Review Sheet</span>
        </div>

        {hasTheReviewBeenSent(lastReview) && !enableReview ? (
          <div className="contentPreview">{parse(lastReview.reviewSheet.content || '')}</div>
        ) : (
          <>
            <div className="reviewContent">
              <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {/* step1 Select Template */}
              {activeStep === 0 && templates ? (
                <div className="templateContainer">
                  {templates.map((template: any, index: number) => (
                    <button
                      className="template"
                      key={index}
                      onClick={() => handleTemplateClick(template)}
                      style={{
                        color: selectedTemplate === template ? '#3478F6' : '#DCDCDC', // Change text color
                        borderColor: selectedTemplate === template ? '#3478F6' : '#DCDCDC', // Change border color
                      }}>
                      <IoDocumentTextOutline className={selectedTemplate === template ? 'selected' : ''} />
                      <p className={selectedTemplate === template ? 'selected' : ''}>{template.name}</p>
                    </button>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {/* step2 Fill in information */}
              {activeStep === 1 ? (
                <div className="informationContainer">
                  <div className="inputContainer">
                    <span>Template</span>
                    <DropdownBtn
                      id="template"
                      placeholder={selectedTemplate?.name || ''}
                      required={true}
                      items={templates.map((template: ReviewSheetTemplate) => {
                        return { id: template.id, label: template.name };
                      })}
                      onClick={handleTemplateClick}
                    />
                  </div>
                  {showApLegalEntityName ? (
                    <div className="inputContainer">
                      <span>AP Legal Entity Name</span>
                      <input value={apLegalEntityName} onChange={e => setApLegalEntityName(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showApplyCode ? (
                    <div className="inputContainer">
                      <span>Number</span>
                      <input value={applyCode} onChange={e => setApplyCode(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showBsmStatus ? (
                    <div className="inputContainer">
                      <span>Status</span>
                      <input value={bsmStatus} onChange={e => setBsmStatus(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showTitleEnglish ? (
                    <div className="inputContainer">
                      <span>Title</span>
                      <input value={englishTitle} onChange={e => setTitleEnglish(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showSignature ? (
                    <div className="inputContainer">
                      <span>Your Signature</span>
                      <input value={signature} onChange={e => setSignature(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="inputContainer">
                    <span>Email</span>
                    <input
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      className={!isValidEmailState ? 'input-error' : ''}
                    />
                    {/* Conditionally render an error message */}
                    {!isValidEmailState && <div className="error-message">Please enter a valid email address.</div>}
                  </div>
                  <div className="inputContainer">
                    <span>Email CC</span>
                    <input
                      value={emailCc}
                      onChange={e => setEmailCc(e.target.value)}
                      className={!isValidEmailCcState ? 'input-error' : ''}
                    />
                    {/* Conditionally render an error message */}
                    {!isValidEmailCcState && <div className="error-message">Please enter a valid email address.</div>}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* step3 Preview & Submit */}
              {activeStep >= 2 ? (
                <div className="rteContainer">
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={{
                      removeButtons: [
                        'classSpan',
                        'file',
                        'video',
                        'speechRecognize',
                        'copyformat',
                        'symbols',
                        'ai-commands',
                        'ai-assistant',
                        'find',
                        'source',
                        'about',
                      ],
                    }}
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {}}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="actionsContainer">
              <div className="leftContent">
                {activeStep >= steps.length - 1 ? (
                  <>
                    <button className="copyButton" onClick={handleCopy}>
                      <MdOutlineFileCopy />
                    </button>
                    <button className="pdfButton" onClick={handlePdf}>
                      <MdOutlinePictureAsPdf />
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="rightContent">
                {activeStep >= steps.length - 1 ? <span>Send to: {email}</span> : <></>}
                <Button className="previousButton" disabled={activeStep === 0} onClick={handleBack}>
                  Previous
                </Button>
                <Button
                  className="nextButton"
                  disabled={selectedTemplate === null || (activeStep === 1 && isNextButtonDisabled())}
                  onClick={activeStep === steps.length - 1 ? handleModal : handleNext}>
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </div>
          </>
        )}

        <Modal
          className="customModal"
          size="sm"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="AnalystDecisionModal">
          <Modal.Header closeButton>
            <Modal.Title className="modalTitle" id="AnalystDecisionModal">
              Submit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalBody">
            {isRequesting ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '32px',
                }}>
                <CircularProgress color="inherit" size={40} />
              </div>
            ) : emailSent ? (
              <div className="bodyContainer success">
                <CgCheckO />
                <p>Email Successfully Sent!</p>
              </div>
            ) : (
              <div className="bodyContainer fail">
                <CgDanger />
                <p>
                  Email <strong>NOT</strong> Sent!
                </p>
              </div>
            )}
          </Modal.Body>
          {isRequesting ? (
            <Modal.Footer></Modal.Footer>
          ) : (
            <Modal.Footer>
              {emailSent ? (
                <Button className="button backButton" onClick={handleBackToHome}>
                  Back to Home
                </Button>
              ) : (
                <div className="footerContainer">
                  <Button className="button backButton" onClick={() => setShowModal(false)}>
                    Back to Review
                  </Button>
                  <Button className="button submitButton" onClick={handleSubmmitModal}>
                    Try Again
                  </Button>
                </div>
              )}
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}
