import './index.scss';
import { LogoLoading, Select } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getColour } from './utils';
import { Container, Card, Button } from 'react-bootstrap';
import { InputLabel } from '@mui/material';
import AnalystDecision from './analyst-decision';
import SummaryTab from './summary-tab';
import TranscriptTab from './transcript-tab';
import ReviewTab from './review-tab';
import ReviewHistoryTab from './review-history-tab';
import { IoMdAttach } from 'react-icons/io';
import AttachmentTab from './attachment-tab';
import { capitalizeFirstLetter } from '../../utils/string-utils';
import { formatSourceDate } from '../dashboard/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as submissionReducer from '../../redux/reducers/submissionReducer';
import { Tab } from '../../interface/tab';
import { Review } from '../../interface/review';
import { hasTheReviewBeenSent } from '../../helpers/reviews-helper';
import submissionService from '../../resources/submission/submission.service';
import { SubmissionDetailsDTO } from '../../interface/submission-details-dto';
import { ReviewIssueViolationRule } from '../../interface/review-issue-violation-rule';
import { SubmissionIssueDTO } from '../../interface/submission-issue-dto';
import { SubmissionVersionsDTO } from '../../interface/submission-versions';
import { isSupportedMediaType, isSubmissionProcessing, hasSubmissionError } from '../../helpers/submission-helper';

export default function SubmissionComponent() {
  const [submissionDetails, setSubmissionDetails] = useState<SubmissionVersionsDTO>();
  const [selectValue, setSelectValue] = useState('');
  const initialized = useRef(false);

  const dispatch = useAppDispatch();
  const { submissionReviews, currentTab } = useAppSelector(state => state.submission);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const revSheetHistTabMustBeDisplayed = reviews.some(review => hasTheReviewBeenSent(review));
  const [tabs, setTabs] = useState<Array<Tab>>([]);
  const [showAttachmentTab, setShowAttachmentTab] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const queryString = window.location.search; // Get the query string from the URL
  const searchParams = new URLSearchParams(queryString); // Create a URLSearchParams object to parse the query string
  const strApplyCode = searchParams.get('applyCode'); // Get the value of the "applyCode" parameter
  const applyCode = strApplyCode !== null ? Number.parseInt(strApplyCode, 10) : 0;

  const records = useMemo(() => {
    if (!submissionDetails) return [];
    return submissionDetails?.submissions;
  }, [submissionDetails]);

  const selectOptions = useMemo(() => {
    if (!submissionDetails) return [];
    return submissionDetails?.submissions.map(f => ({
      name: formatSourceDate(f.sourceDate) + ' - ' + f.sourceLocation.split('/').reverse()[0],
      value: f.sourceLocation,
    }));
  }, [submissionDetails]);

  const setSelectedFile = useCallback((file: SubmissionDetailsDTO) => {
    dispatch(submissionReducer.setCurrentRecord(file));
    videoRef.current?.load();
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      // Avoiding duplicated calls on page start
      initialized.current = true;
      fetchSubmission(applyCode);
    }
  }, []);

  useEffect(() => {
    if (submissionDetails) {
      setSelectValue(submissionDetails?.submissions[0].sourceLocation);
      setSelectedFile(submissionDetails?.submissions[0]);
      dispatch(submissionReducer.setSubmissionReviews(submissionDetails?.submissionsReviews[0]));
      dispatch(submissionReducer.setReviews(submissionDetails?.reviews[0]));
    }
  }, [submissionDetails, setSelectedFile]);

  useEffect(() => {
    setTabs([
      { id: 0, name: 'Summary', show: true },
      { id: 1, name: 'Transcript', show: trAndRevTabsMustBeDisplayed() },
      { id: 2, name: 'Review', show: trAndRevTabsMustBeDisplayed() },
      { id: 3, name: 'Review History', show: revSheetHistTabMustBeDisplayed },
    ]);
  }, [currentRecord]);

  function trAndRevTabsMustBeDisplayed() {
    if (
      !isSupportedMediaType(currentRecord.sourceLocation) ||
      isSubmissionProcessing(currentRecord) ||
      hasSubmissionError(currentRecord)
    ) {
      return false;
    }

    return true;
  }

  function fetchSubmission(applyCode: number) {
    submissionService
      .getSubmission(applyCode)
      .promise.then((submissionDetails: SubmissionVersionsDTO) => {
        setSubmissionDetails(submissionDetails);
      })
      .catch((error: Error) => {
        console.error(error.message);
      });
  }

  /**
   * Event triggered when the user changes submission version.
   * @param event
   */
  function handleVersionChange(event: any) {
    const selectedValue = event.target.value;

    // Find the index of the selected item in the records array
    const selectedIndex = records.findIndex(f => f.sourceLocation === selectedValue);

    // If the selected item is found, set the selected file and submission reviews
    if (selectedIndex !== -1) {
      setSelectValue(selectedValue);
      setSelectedFile(records[selectedIndex]);
      dispatch(submissionReducer.setSubmissionReviews(submissionDetails?.submissionsReviews[selectedIndex] || []));
      dispatch(submissionReducer.setReviews(submissionDetails?.reviews[selectedIndex]));
    }
  }

  function canSubmitReview() {
    return submissionReviews?.every((submissionReview: SubmissionIssueDTO) => {
      const userReviewStatus = submissionReview.reviewIssueViolationRule.every(
        (issue: ReviewIssueViolationRule) => issue.userReviewStatus !== null,
      );

      return userReviewStatus;
    });
  }

  function handleReviewTab() {
    if (canSubmitReview()) {
      const reviewTab = tabs.find(tab => tab.name === 'Review') as Tab;
      dispatch(submissionReducer.changeCurrentTab(reviewTab));
    } else {
      const transcriptTab = tabs.find(tab => tab.name === 'Transcript') as Tab;
      dispatch(submissionReducer.changeCurrentTab(transcriptTab));
      dispatch(submissionReducer.setIsReviewAlertActive(true));
      const reviewAlertTimeout = setTimeout(() => {
        dispatch(submissionReducer.setIsReviewAlertActive(false));
      }, 120000);
      dispatch(submissionReducer.setReviewAlertTimeout(reviewAlertTimeout));
    }
  }

  function activeTab(tabId: number) {
    return currentTab.id === tabId;
  }

  function handleTabClick(tab: Tab) {
    if (tab.name === 'Review') {
      handleReviewTab();
    } else {
      dispatch(submissionReducer.changeCurrentTab(tab));
    }
  }

  return (
    <Container>
      {selectValue !== '' ? (
        <Card>
          <Card.Body>
            <div className="submission-title-container">
              <h4 className="submission-title">"{currentRecord.englishTitle}"</h4>
              <div className="submission-version-container">
                <InputLabel id="selectVersion" className="submission-select-version-label">
                  Version:
                </InputLabel>
                <Select
                  id="selectVersion"
                  className="select-version"
                  onChange={handleVersionChange}
                  options={selectOptions}
                  value={selectValue}
                  placeholder=""
                  required
                  size="sm"
                  variant="secondary"
                />
              </div>
            </div>

            <div className="submission-header-container">
              <div className="line-before-tab"></div>
              <div className="tab-buttons">
                {tabs.map((tab: Tab, index: number) => (
                  <Button
                    className={`tab-button ${activeTab(index) ? 'active' : ''}`}
                    hidden={!tab.show}
                    onClick={() => handleTabClick(tab)}
                    key={index}>
                    <div>{tab.name}</div>
                  </Button>
                ))}
              </div>

              {currentRecord && (
                <div className="submission-header-content">
                  <div className="decision">
                    <div className="decision-details">
                      <span className="decision-title">
                        {currentRecord.modelDecision !== null ? 'ML Recomendation:' : ''}
                      </span>
                      <span className={'decision-value ' + getColour(currentRecord.modelDecision)}>
                        {currentRecord.modelDecision !== null
                          ? capitalizeFirstLetter(currentRecord.modelDecision.toLowerCase())
                          : ''}
                      </span>

                      <span className="decision-title">{currentRecord.decisionReason ? 'Reason:' : ''}</span>
                      <span className="decision-value">
                        {currentRecord.decisionReason ? capitalizeFirstLetter(currentRecord.decisionReason) : ''}
                      </span>
                    </div>
                  </div>
                  <div className="right-side-items">
                    <Button
                      className={`attachments-button ${showAttachmentTab ? 'attachments-button-active' : ''}`}
                      onClick={() => setShowAttachmentTab(!showAttachmentTab)}>
                      <IoMdAttach size={20} />
                      Attachments
                    </Button>
                    <AttachmentTab
                      isOpened={showAttachmentTab}
                      onClose={() => setShowAttachmentTab(false)}
                      onCancel={() => setShowAttachmentTab(false)}
                    />
                    <AnalystDecision />
                  </div>
                </div>
              )}
            </div>

            <div className="submission-content">
              {currentTab.name === 'Summary' && <SummaryTab />}

              {currentTab.name === 'Transcript' && <TranscriptTab />}

              {currentTab.name === 'Review' && <ReviewTab />}

              {currentTab.name === 'Review History' && <ReviewHistoryTab />}
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="loading-screen-submission">
          <LogoLoading />
        </div>
      )}
    </Container>
  );
}
