import React, { ReactNode, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import './index.scss';

interface FilterOption {
  id: string;
  description?: string;
  label: string | ReactNode;
}

interface MultiStateSwitchProps {
  options: FilterOption[];
  name: string;
  onChange: (value: string) => void;
  defaultOption: string;
  label?: string;
}

export default function MultiStateSwitchComponent({
  onChange,
  options,
  name,
  label,
  defaultOption,
}: MultiStateSwitchProps) {
  const [selectedOption, setSelectedOption] = useState<string>(defaultOption || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    onChange(defaultOption);
  }, []);

  return (
    <div className="multi-state-switch-container">
      {label ? (
        <label className="multi-state-switch-label" htmlFor="multi-state-switch">
          {label}
        </label>
      ) : (
        <></>
      )}
      <ul id={name} className="multi-state-switch-options">
        {options.map(option => (
          <li key={option.id} className="multi-state-switch-option">
            <input
              type="radio"
              name={name}
              id={option.id}
              value={option.id}
              checked={selectedOption === option.id}
              onChange={handleChange}
              className="multi-state-switch-option-input"
            />
            <label
              htmlFor={option.id}
              data-tooltip-id={`${name}-'tooltip'`}
              data-tooltip-content={option.description}
              className={`multi-state-switch-option-label ${selectedOption === option.id ? 'selected' : ''}`}>
              {option.label}
            </label>
          </li>
        ))}
      </ul>
      <Tooltip id={`${name}-'tooltip'`} place="top" />
    </div>
  );
}
