import { IssueOccurrence } from './../../interface/issue-occurrence';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { ViolationRule } from '../../resources/violation-rule/violation-rule-types';
import { Occurrence } from '../../interface/issue-occurrence';
import { CustomOptionType } from '../../screens/submission/transcript-component/custom-language-select/custom-language-select';

interface TranscriptState {
  transcriptTabErrorMessage: string;
  showTranscriptAction: boolean;
  transcriptSelectedContext: string;
  violationRules: ViolationRule[];
  nestedViolationRules: ViolationRule[];
  selectedIssueOccurrence: Occurrence;
  currentTab: string;
  filteredIssuesOccurrences: IssueOccurrence[];
  filteredIssuesRuleViolations: IssueOccurrence[];
  filteredIssuesKeywords: IssueOccurrence[];
  isDisplayingRejected: boolean;
  byPassSync: boolean;
  byPassSelection: boolean;
  isRvActionsRequesting: boolean;
  mergedTranscript: string;
  mergedSegments: Array<string>;
  isTranscriptTabLoading: boolean;
  selectedLanguage: CustomOptionType;
}

const initialState: TranscriptState = {
  transcriptTabErrorMessage: '',
  showTranscriptAction: false,
  transcriptSelectedContext: '',
  violationRules: [],
  nestedViolationRules: [],
  selectedIssueOccurrence: {} as Occurrence,
  currentTab: 'Rule Violation',
  filteredIssuesOccurrences: [],
  filteredIssuesRuleViolations: [],
  filteredIssuesKeywords: [],
  isDisplayingRejected: false,
  byPassSync: false,
  byPassSelection: false,
  isRvActionsRequesting: false,
  mergedTranscript: '',
  mergedSegments: [],
  isTranscriptTabLoading: false,
  selectedLanguage: {} as CustomOptionType,
};

const transcriptSlice: Slice = createSlice({
  name: 'transcript',
  initialState: initialState,
  reducers: {
    setTranscriptTabErrorMessage: (state, action: PayloadAction<string>) => {
      state.transcriptTabErrorMessage = action.payload;
    },
    setShowTranscriptAction: (state, action: PayloadAction<boolean>) => {
      state.showTranscriptAction = action.payload;
    },
    setTranscriptSelectedContext: (state, action: PayloadAction<string>) => {
      state.transcriptSelectedContext = action.payload;
    },
    setViolationRules: (state, action: PayloadAction<ViolationRule[]>) => {
      state.violationRules = action.payload;
    },
    setNestedViolationRules: (state, action: PayloadAction<ViolationRule[]>) => {
      state.nestedViolationRules = action.payload;
    },
    setSelectedIssueOccurrence: (state, action: PayloadAction<Occurrence>) => {
      state.selectedIssueOccurrence = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    setFilteredIssuesOccurrences: (state, action: PayloadAction<IssueOccurrence[]>) => {
      state.filteredIssuesOccurrences = action.payload;
    },
    setFilteredIssuesRuleViolations: (state, action: PayloadAction<IssueOccurrence[]>) => {
      state.filteredIssuesRuleViolations = action.payload;
    },
    setFilteredIssuesKeywords: (state, action: PayloadAction<IssueOccurrence[]>) => {
      state.filteredIssuesKeywords = action.payload;
    },
    setIsDisplayingRejected: (state, action: PayloadAction<boolean>) => {
      state.isDisplayingRejected = action.payload;
    },
    setByPassSync: (state, action: PayloadAction<boolean>) => {
      state.byPassSync = action.payload;
    },
    setByPassSelection: (state, action: PayloadAction<boolean>) => {
      state.byPassSelection = action.payload;
    },
    setIsRvActionsRequesting: (state, action: PayloadAction<boolean>) => {
      state.isRvActionsRequesting = action.payload;
    },
    setMergedTranscript: (state, action: PayloadAction<string>) => {
      state.mergedTranscript = action.payload;
    },
    setMergedSegments: (state, action: PayloadAction<Array<string>>) => {
      state.mergedSegments = action.payload;
    },
    setIsTranscriptTabLoading: (state, action: PayloadAction<boolean>) => {
      state.isTranscriptTabLoading = action.payload;
    },
    setSelectedLanguage: (state: TranscriptState, action: PayloadAction<CustomOptionType>) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const {
  setTranscriptTabErrorMessage,
  setShowTranscriptAction,
  setTranscriptSelectedContext,
  setViolationRules,
  setNestedViolationRules,
  setSelectedIssueOccurrence,
  setCurrentTab,
  setFilteredIssuesOccurrences,
  setFilteredIssuesRuleViolations,
  setFilteredIssuesKeywords,
  setIsDisplayingRejected,
  setByPassSync,
  setByPassSelection,
  setIsRvActionsRequesting,
  setMergedTranscript,
  setMergedSegments,
  setIsTranscriptTabLoading,
  setSelectedLanguage,
} = transcriptSlice.actions;

export default transcriptSlice.reducer;
