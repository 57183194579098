import { IssueHightlight } from '../../resources/submission/submission-types';
import { ViolationRule } from '../../resources/violation-rule/violation-rule-types';
import { videoTimeToSeconds } from '../../utils/video-utils';
import { format, parseISO } from 'date-fns';
import { escapeRegExp } from '../../utils/string-utils';
import { ReviewIssueViolationRule } from '../../interface/review-issue-violation-rule';
import { SubmissionIssueDTO } from '../../interface/submission-issue-dto';
import { ISegment } from '../../interface/segment';

interface SegmentTime {
  startTime: number;
  endTime: number;
}

export function findNthContainedStringIndex(array: any, keyword: any, instanceNumber: any) {
  let count = 0;

  for (let i = 0; i < array.length; i++) {
    if (array[i].includes(keyword)) {
      count++;
      if (count === instanceNumber) {
        return i;
      }
    }
  }

  return -1; // Return -1 if the n-th instance is not found.
}

export function findIssueSegmentTextByTime(segments: ISegment[] | null, currentTime: number): SegmentTime | undefined {
  if (segments) {
    for (let i = 0; i < segments.length; i++) {
      if (currentTime >= Math.floor(segments[i].start) && currentTime < Math.floor(segments[i].end)) {
        return { startTime: Math.floor(segments[i].start), endTime: Math.floor(segments[i].end) };
      }
    }
    return { startTime: 0, endTime: 0 };
  }
}

export function getColour(record: string) {
  let colour = '';
  if (record && typeof record === 'string') {
    switch (record.toLowerCase()) {
      case 'accept':
        colour = 'accept';
        break;
      case 'reject':
        colour = 'reject';
        break;
      case 'authorizing':
        colour = 'accept';
        break;
      case 'confirming issues':
        colour = 'reject';
        break;
      case 'high':
        colour = 'High';
        break;
      case 'medium':
        colour = 'Medium';
        break;
      case 'low':
        colour = 'Low';
        break;
    }
  }
  return colour;
}

export function getAllRuleViolationDetails(
  issuesRuleViolation: ReviewIssueViolationRule[],
  segmentText: any,
  violationRules: ViolationRule[],
): string {
  let allText = '';
  for (let index = 0; index < issuesRuleViolation.length; index++) {
    const element = issuesRuleViolation[index];
    allText +=
      violationRules.find(vr => vr.id === element.violationRuleId)?.name +
      ' ' +
      element.description +
      ' ' +
      segmentText +
      '';
  }

  return allText;
}

export function createKeywordSentence(segments: any, keySentenceIndex: number, keyword: string, sentenceSize: number) {
  //the segment of the trascript that we have the keyword, to an array
  const lineArray = (segments[keySentenceIndex]?.text).split(/(?=[ ])|(?<=[,.;/?!])/);
  const lineAfterArray = (keySentenceIndex + 1 < segments.length - 2 ? segments[keySentenceIndex + 1]?.text : '').split(
    /(?=[ ])|(?<=[,.;/?!])/,
  );
  const lineBeforeArray = (keySentenceIndex - 1 > 0 ? segments[keySentenceIndex - 1]?.text : '').split(
    /(?=[ ])|(?<=[,.;/?!])/,
  );

  let index = 0; //index of keyword in the lineArray
  for (index; index < lineArray.length; index++) {
    if (lineArray[index].indexOf(keyword) !== -1) {
      break;
    }
  }

  //how many words will be used from each line
  const qttLine = lineArray.length;
  const qttLineBefore = Math.floor((sentenceSize - qttLine) / 2) < 0 ? 0 : Math.floor((sentenceSize - qttLine) / 2);
  const qttLineAfter = sentenceSize - qttLine - qttLineBefore < 0 ? 0 : sentenceSize - qttLine - qttLineBefore;

  let sentenceArray; //get the words together to form the sentence
  sentenceArray = lineBeforeArray.slice(Math.max(0, lineBeforeArray.length - qttLineBefore), lineBeforeArray.length);
  sentenceArray = sentenceArray.concat(
    lineArray,
    lineAfterArray.slice(0, Math.min(qttLineAfter, lineAfterArray.length)),
  );

  let sentence: string = ''; //array to string
  for (let i = 0; i < sentenceArray.length; i++) {
    sentence = sentence + sentenceArray[i];
  }

  return sentence;
}

/**
 *
 * @param segments
 * @returns a new string with only the times corresponding the transcript
 */
export function stringTimestamp(segments: ISegment[] | null): string {
  let stringTimestamp: string = '';

  if (!segments) {
    return '';
  }

  for (let i = 0; i < segments.length; i++) {
    const segmentLength = segments[i].text.replace(/\s/g, ' ').length;

    if (segmentLength >= 5) {
      const time = Math.floor(segments[i].start).toString().padStart(5, '0');
      stringTimestamp = stringTimestamp + `${time}${' '.repeat(segmentLength - 5)}`;
    } else {
      stringTimestamp = stringTimestamp + `${' '.repeat(segmentLength)}`;
    }
  }
  return stringTimestamp;
}

/**
 * Find the time corresponding the start an end of the rule violation in the transcript
 *
 * @param stringTimestamp a string with only the timestamps of the transcript
 * @param transcript a text transcript from an audio or a video
 * @param issueText a rule violiation text
 * @returns start and end time of the issue/rule violation
 */
export function findIssueStartTime(stringTimestamp: string, transcript: string, issueText: string) {
  const index = transcript.toLowerCase().indexOf(issueText.toLowerCase());
  const issueLength = issueText.length;

  if (index === -1) {
    return { startTime: 0, endTime: 0 };
  }
  let timeStart = stringTimestamp.substring(index - 1, index + 4);
  let timeEnd = stringTimestamp.substring(index + issueLength, index + issueLength + 5);

  if (!new RegExp(/\d{5}$/).test(timeStart)) {
    timeStart = findTimeBefore(index + timeStart.trim().length, stringTimestamp)!;
  }
  if (!new RegExp(/\d{5}$/).test(timeEnd)) {
    timeEnd = findTimeAfter(index - timeEnd.trim().length, stringTimestamp)!;
  }

  return { startTime: videoTimeToSeconds(timeStart), endTime: videoTimeToSeconds(timeEnd) };
}

/**
 * Finds the previous time marked in the transcript, based on the issue text index
 *
 * @param index of the issue text in the transcript
 * @param stringTimestamp a string with only the timestamps of the transcript
 * @returns start and end time of the issue
 */
function findTimeBefore(index: number, stringTimestamp: string) {
  // if not in the middle of the first position
  if (index > 5) {
    // get substring from the begging until the index found
    // make an array where each position is a caracter
    const timestampCaracter = stringTimestamp.substring(0, index).split('');

    for (let i = timestampCaracter.length - 1; i > 0; i--) {
      // starts looking backwards until find a caracter
      if (timestampCaracter[i] != ' ') {
        //get the substring that represents the time
        return stringTimestamp.substring(i - 4, i + 1);
      }
    }
  } else {
    return '00:00';
  }
}

function findTimeAfter(index: number, stringTimestamp: string) {
  // if not in the middle of the first position
  if (index > 5) {
    // get substring from the index found until the end
    // make an array where each position is a caracter
    const timestampCaracter = stringTimestamp.substring(index, stringTimestamp.length).split('');

    for (let i = 0; i < timestampCaracter.length; i++) {
      // starts looking until find a caracter
      if (timestampCaracter[i] != ' ') {
        //get the substring that represents the time
        //return stringTimestamp.substring(i-4, i+1);
        return stringTimestamp.substring(index + i, index + i + 5);
      }
    }
  } else {
    return '00:00';
  }
}

export function addSpanToText(
  strTranscript: string,
  startTime: string,
  endTime: string,
  content: string,
  context: string | null,
  color: string,
  issueDivId: string,
  mode: string,
  issues: IssueHightlight[],
  originalTranscript?: string,
  timestampString?: string,
  mergedSegments?: ISegment[],
  issuesIds?: number[],
) {
  let firstIndex = context && originalTranscript ? originalTranscript.toLowerCase().indexOf(context.toLowerCase()) : -1;
  if (!issuesIds) issuesIds = [];

  if (startTime && timestampString && context && mergedSegments) {
    let contextTimeStart = startTime;

    // Try to find context index based on saved time
    let contextIndex = originalTranscript!
      .toLowerCase()
      .indexOf(context.toLowerCase(), timestampString.indexOf(contextTimeStart));
    if (contextIndex === -1) {
      // If not match only by text
      // This is necessary because some context are saving the wrong timestamp
      contextIndex = originalTranscript!.toLowerCase().indexOf(context.toLowerCase());
    }

    let sections = timestampString.substring(0, contextIndex + 5);
    let splittedSections = getSections(sections);
    if (splittedSections && splittedSections.length) {
      let lastSection = parseInt(splittedSections.at(-1) || startTime);
      let segment = mergedSegments.filter((segment: any) => segment.start === lastSection);
      if (segment && segment.length) {
        contextTimeStart = segment[0].start.toString();
      }
    }

    let padStartTime = contextTimeStart.padStart(5, '0');
    let contextSegmentIndex = timestampString.indexOf(padStartTime);
    firstIndex =
      contextSegmentIndex +
      originalTranscript!.substring(contextSegmentIndex).toLowerCase().indexOf(context.toLowerCase());
  }

  // If we don't have original transcript, we are building segments.
  if (!originalTranscript) {
    let firstIndex = strTranscript.toLowerCase().indexOf(content.toLowerCase());

    if (firstIndex === -1) {
      console.error('segment not found on transcript:', content);
      return strTranscript;
    }

    const indexEnd = firstIndex + content.length;
    const transcriptBeforeText = strTranscript.substring(0, firstIndex);
    const transcriptAfterText = strTranscript.substring(indexEnd, strTranscript.length);
    // We add double underline on those special words so they are not replaced with the transcript content as this can breake the html.
    // After adding all the spans we can remove them with a replaceAll.
    const textStart = `<s__p__a__n i__d__=__"__s__p__n__K__e__y__W__o__r__d_${startTime}" c__l__a__s__s="i__s__s__u__e-t__e__x__t-m__a__t__c__h ${color}" d__a__t__a-s__t__a__r__t="${startTime}" d__a__t__a-e__n__d="${endTime}"  d__a__t__a-d__i__v="${issueDivId}" d__a__t__a-i__s__s__u__e__s="${issuesIds?.join(
      ',',
    )}" >`;
    const textEnd = `</s__p__a__n>`;

    strTranscript = transcriptBeforeText + textStart + content.replaceAll(' ', ' ___') + textEnd + transcriptAfterText;

    return strTranscript;
  }

  // If we have original transcript, we are building RV or Keywords.
  // Has context (user interacted) but issues is null (old cases).
  if (context && content && issues.length === 0) {
    let contextIndex = originalTranscript.toLowerCase().indexOf(context.toLowerCase()) + 1;
    let relativeContentIndex = context.toLowerCase().indexOf(content.toLowerCase()) + 1;
    let section = timestampString!.substring(contextIndex, contextIndex + relativeContentIndex + content.length);
    let firstBlankSpaceIndex = section.indexOf(' ');
    let addedSize = 0;

    if (firstBlankSpaceIndex > 0 && firstBlankSpaceIndex < 5) {
      section = timestampString!.substring(
        contextIndex - (5 - firstBlankSpaceIndex),
        contextIndex + relativeContentIndex + content.length,
      );
      addedSize = firstBlankSpaceIndex - 1;
    }

    let sections = getSections(section);
    let lastSection = [sections!.at(-1)!][0];
    let relativeLastSectionIndex = section!.indexOf(lastSection) + 1;
    let trueIndexStart = strTranscript.indexOf(`s__p__n__K__e__y__W__o__r__d_${parseInt(lastSection)}`);
    let trueIndexEnd = trueIndexStart + strTranscript.substring(trueIndexStart).indexOf('>') + 1;

    if (addedSize) {
      trueIndexEnd = trueIndexEnd + relativeContentIndex;
    } else {
      trueIndexEnd = trueIndexEnd + relativeContentIndex - relativeLastSectionIndex - 1;
    }

    let textToBeReplaced = strTranscript.substring(trueIndexEnd, trueIndexEnd + content.length);

    let textToBeReplacedCheck = checkTextToBeReplaced(textToBeReplaced);
    if (!textToBeReplacedCheck) return strTranscript;

    return transcriptReplace(
      strTranscript,
      trueIndexEnd,
      section,
      color,
      startTime,
      issueDivId,
      content,
      content.length,
      issuesIds,
    );
  }

  // From ML that the user has not interacted yet OR a keyword OR issues json not present.
  if (!context && content) {
    firstIndex = originalTranscript.toLowerCase().indexOf(content.toLowerCase());

    // If we are adding keyword, check if the startTime is already present within a highlight.
    if (mode === 'keyword') {
      let presentHighlightEl = document.querySelector(`#s__p__n__K__e__y__W__o__r__d_${startTime}.red`);
      if (presentHighlightEl) {
        return strTranscript;
      }
    }

    let section = timestampString!.substring(firstIndex, firstIndex + content.length);
    let finalSection = '';
    let previousSectionData = getPreviousSectionData(timestampString!, section, firstIndex, content.length);
    let firstPieceSize = previousSectionData.firstPieceSize || 0;
    let firstPieceSizeIndexStart = previousSectionData.firstPieceSizeIndexStart || 0;
    let previousSection = previousSectionData.previousSection;
    let nextSectionData = getNextSectionData(timestampString!, section, firstIndex, content.length);
    let nextSection = nextSectionData.nextSection;
    let lastPieceSize = nextSectionData.lastPieceSize || 0;
    let originalTimeStampSection = section;
    let sections = getSections(section) || [];

    if (previousSection) {
      sections?.splice(0, 0, previousSection);
    }
    if (nextSection) {
      sections?.push(nextSection);
    }

    // Building the final section (including previous and next section if needed).
    finalSection = timestampString!.substring(
      timestampString!.indexOf(sections.at(0)!),
      timestampString!.indexOf(sections.at(-1)!) + 5,
    );

    // RV in single section case.
    if (section.trim().split(' ').length === 1 && section.trim().split(' ')[0] === '' && previousSection) {
      strTranscript = processSingleSectionReplace(
        strTranscript,
        timestampString!,
        previousSection,
        firstIndex,
        color,
        startTime,
        issueDivId,
        content,
        content.length,
        issuesIds,
      );

      return strTranscript;
    }

    if (sections && sections.length) {
      strTranscript = replaceSectionsOnTranscript(
        strTranscript,
        originalTimeStampSection,
        sections,
        section,
        firstPieceSizeIndexStart,
        firstPieceSize,
        lastPieceSize,
        color,
        startTime,
        issueDivId,
        issuesIds,
      );
    }
  } else if (context) {
    // User already interacted with.
    if (firstIndex === -1) return strTranscript;
    // Loop through each issue and replace on the transcript
    if (issues.length) {
      const filteredAndSortedIssues = issues
        .filter(issue => issue.issueContent !== '')
        .sort((a, b) => b.issueIndex - a.issueIndex);

      filteredAndSortedIssues.forEach(issue => {
        if (context.toLowerCase().indexOf(issue.issueContent.toLowerCase()) === -1) {
          console.error('Content was not found in the context:', issue.issueContent);
          return strTranscript;
        }
        // Context index plus current issue index give us the true start index of the issue.
        const issueIndexStart = firstIndex + issue.issueIndex;
        const issueIndexEnd = issueIndexStart + issue.issueContent.length;
        let contextSection = timestampString!.slice(issueIndexStart, issueIndexEnd);
        let originalTimeStampSection = contextSection;
        let sections = getSections(contextSection) || [];

        let previousSectionData = getPreviousSectionData(
          timestampString!,
          contextSection,
          issueIndexStart,
          issue.issueContent.length,
        );
        let firstPieceSize = previousSectionData.firstPieceSize || 0;
        let firstPieceSizeIndexStart = previousSectionData.firstPieceSizeIndexStart || 0;
        let previousSection = previousSectionData.previousSection;

        if (
          contextSection.trim().split(' ').length === 1 &&
          contextSection.trim().split(' ')[0] === '' &&
          previousSection
        ) {
          // RV in a single section!
          strTranscript = processSingleSectionReplace(
            strTranscript,
            timestampString!,
            previousSection,
            issueIndexStart,
            color,
            startTime,
            issueDivId,
            issue.issueContent,
            issue.issueContent.length,
            issuesIds || [],
          );

          return strTranscript;
        }

        let nextSectionData = getNextSectionData(
          timestampString!,
          contextSection,
          issueIndexStart,
          issue.issueContent.length,
        );
        let nextSection = nextSectionData.nextSection;
        let lastPieceSize = nextSectionData.lastPieceSize || 0;

        if (previousSection) {
          sections?.splice(0, 0, previousSection);
        }
        if (nextSection) {
          sections?.push(nextSection);
        }

        // Building the final section (including previous and next section if needed).
        let finalSection = timestampString!.substring(
          timestampString!.indexOf(sections.at(0)!),
          timestampString!.indexOf(sections.at(-1)!) + 5,
        );

        if (sections && sections.length) {
          strTranscript = replaceSectionsOnTranscript(
            strTranscript,
            originalTimeStampSection,
            sections,
            finalSection,
            firstPieceSizeIndexStart,
            firstPieceSize,
            lastPieceSize,
            color,
            startTime,
            issueDivId,
            issuesIds || [],
          );
        }
      });
    }
  }

  return strTranscript;
}

// Handles replace of text in a single seciton.
function processSingleSectionReplace(
  strTranscript: string,
  timestampString: string,
  previousSection: string,
  issueIndexStart: number,
  color: string,
  startTime: string,
  issueDivId: string,
  content: string,
  contentLength: number,
  issuesIds: number[],
) {
  let sectionIndex = timestampString!.indexOf(previousSection);
  let firstPieceSizeIndexStart = issueIndexStart - sectionIndex;
  let trueIndexStart = strTranscript.indexOf(`s__p__n__K__e__y__W__o__r__d_${parseInt(previousSection)}`);
  let trueIndexEnd =
    trueIndexStart + strTranscript.substring(trueIndexStart).indexOf('>') + 1 + firstPieceSizeIndexStart;
  let textToBeReplaced = strTranscript.substring(trueIndexEnd, trueIndexEnd + contentLength);
  let textToBeReplacedCheck = checkTextToBeReplaced(textToBeReplaced);

  if (!textToBeReplacedCheck) return strTranscript;

  strTranscript = transcriptReplace(
    strTranscript,
    trueIndexEnd,
    previousSection,
    color,
    startTime,
    issueDivId,
    content,
    contentLength,
    issuesIds,
  );

  return strTranscript;
}

function getPreviousSectionData(timestampString: string, section: string, firstIndex: number, contentLength: number) {
  let firstBlankSpaceIndex = section.indexOf(' ');
  let previousSection;
  let firstPieceSize;
  let firstPieceSizeIndexStart;

  if (firstBlankSpaceIndex === 0) {
    // If the first char is an empty string, we need to get the previous section.
    let previousSections = timestampString!.substring(0, firstIndex);
    previousSection = getSections(previousSections)?.at(-1);
    let firstSection = getSections(section)?.at(0);

    if (!firstSection) {
      firstSection = getSections(timestampString!.substring(firstIndex, firstIndex + contentLength + 4))?.at(0);
    }

    firstPieceSize = section.indexOf(section.trim().split(' ')[0]);

    firstPieceSizeIndexStart =
      timestampString!.substring(timestampString!.indexOf(previousSection!), timestampString!.indexOf(firstSection!))
        .length - section.indexOf(section.trim().split(' ')[0]);
  } else if (firstBlankSpaceIndex >= -1 && firstBlankSpaceIndex < 5) {
    // If the section starts in a middle of the timestamp we need to get the full timestamp.
    if (firstBlankSpaceIndex === -1) {
      firstBlankSpaceIndex = firstBlankSpaceIndex < 0 ? 0 : firstBlankSpaceIndex;
      let previousSections = timestampString!.substring(firstIndex - 4, firstIndex + 4);
      previousSection = getSections(previousSections)?.at(-1);
      firstPieceSizeIndexStart = firstIndex - timestampString!.indexOf(previousSection!);
      firstPieceSize = contentLength;
    } else {
      let previousSections = timestampString!.substring(
        firstIndex - 5 + firstBlankSpaceIndex,
        firstIndex + firstBlankSpaceIndex,
      );
      previousSection = getSections(previousSections)?.at(-1);
      firstPieceSizeIndexStart = 5 - firstBlankSpaceIndex;
      let firstSection = getSections(section)?.at(0);
      if (firstSection) {
        firstPieceSize = section.indexOf(firstSection);
      } else {
        firstPieceSize = section.lastIndexOf(' ') + 1;
      }
    }
  }

  return {
    previousSection: previousSection,
    firstPieceSize: firstPieceSize,
    firstPieceSizeIndexStart: firstPieceSizeIndexStart,
  };
}

function getNextSectionData(timestampString: string, section: string, firstIndex: number, contentLength: number) {
  let lastSectionSize = section.length - 1 - section.lastIndexOf(' ');
  let lastPieceSize;
  let nextSection;

  if (lastSectionSize > 0 && lastSectionSize < 5) {
    // Check if section ends in the middle of a timestamp.
    let lastSections = timestampString!.substring(firstIndex, firstIndex + contentLength + (5 - lastSectionSize));
    nextSection = getSections(lastSections)?.at(-1);
    lastPieceSize = lastSectionSize;
  } else {
    let lastSection = getSections(section)?.at(-1);
    if (lastSection) {
      lastPieceSize = section.length - section.lastIndexOf(lastSection);
    }
  }

  return { nextSection: nextSection, lastPieceSize: lastPieceSize };
}

// Check whether the text to be replaced was already replaced by another iteration.
function checkTextToBeReplaced(textToBeReplaced: string): boolean {
  if (
    textToBeReplaced.includes('>') ||
    textToBeReplaced.includes('<') ||
    textToBeReplaced.includes('__') ||
    textToBeReplaced === ''
  )
    return false;
  return true;
}

/**
 * Retrieve the previous section of a section.
 * @param joinedSegments List of all segments.
 * @param sections String with sections.
 * @returns The previous section.
 */
function findPreviousTimeStamp(joinedSegments: ISegment[], sections: string[]): ISegment | undefined {
  return joinedSegments.find(segment => Math.floor(segment.end) === parseInt(sections[0]));
}

/**
 * Regex to split by 5 digits string to get the timestamp sections.
 * @param section String with sections.
 * @returns List of sections.
 */
export function getSections(section: string): string[] | null {
  const regex = /\b\d{5}\b/g;
  return section.match(regex);
}

function replaceSectionsOnTranscript(
  strTranscript: string,
  originalTimeStampSection: string,
  sections: string[],
  timeStampSection: string,
  firstPieceSizeIndexStart: number,
  firstPieceSize: number,
  lastPieceSize: number,
  color: string,
  startTime: string,
  issueDivId: string,
  issuesIds: number[],
) {
  // Replacing each piece of section.
  sections.forEach((section, index) => {
    // Get current section size.
    let currentSegmentSize = timeStampSection.indexOf(sections![index + 1]) - timeStampSection.indexOf(section);

    if (currentSegmentSize <= 0 && sections.length > 1) {
      currentSegmentSize = lastPieceSize;
    }

    // Finding the true section in the current transcript state.
    let trueIndexStart = strTranscript.indexOf(`s__p__n__K__e__y__W__o__r__d_${parseInt(section)}`);
    let trueIndexEnd = trueIndexStart + 1 + strTranscript.substring(trueIndexStart).indexOf('>');

    if (index === 0) {
      currentSegmentSize = firstPieceSize;
      trueIndexEnd += firstPieceSizeIndexStart;

      // First section starts exactly at segment begin.
      if (currentSegmentSize <= 0) {
        // If there is only this section, we should use the timeStamp section length.
        if (sections.length === 1) {
          currentSegmentSize = originalTimeStampSection.length;
        } else {
          // Otherwise we need to get the segment length.
          currentSegmentSize =
            originalTimeStampSection.indexOf(sections[index + 1]) - originalTimeStampSection.indexOf(section);
        }
      }
    } else if (index === sections.length - 1) {
      currentSegmentSize = lastPieceSize;
    }

    let textToBeReplaced = strTranscript.substring(trueIndexEnd, trueIndexEnd + currentSegmentSize);
    let textToBeReplacedCheck = checkTextToBeReplaced(textToBeReplaced);

    if (!textToBeReplacedCheck) {
      // TODO: Figure out correct logic to not need this fail safe
      textToBeReplaced = strTranscript.substring(trueIndexEnd, trueIndexEnd + lastPieceSize);
      currentSegmentSize = lastPieceSize;
      textToBeReplacedCheck = checkTextToBeReplaced(textToBeReplaced);
    }

    if (!textToBeReplacedCheck) return strTranscript;

    strTranscript = transcriptReplace(
      strTranscript,
      trueIndexEnd,
      section,
      color,
      startTime,
      issueDivId,
      textToBeReplaced,
      currentSegmentSize,
      issuesIds,
    );
  });

  return strTranscript;
}

// Replace the text in a span on the actual transcript.
function transcriptReplace(
  strTranscript: string,
  trueIndexEnd: number,
  section: string,
  color: string,
  startTime: string,
  issueDivId: string,
  textToBeReplaced: string,
  currentSegmentSize: number,
  issuesIds: number[],
): string {
  strTranscript =
    strTranscript.substring(0, trueIndexEnd) +
    `<s__p__a__n id="s__p__n__K__e__y__W__o__r__d_${parseInt(
      section,
    )}" c__l__a__s__s="i__s__s__u__e-t__e__x__t-m__a__t__c__h ${color}" d__a__t__a-s__t__a__r__t="${startTime}" d__a__t__a-d__i__v="${issueDivId}" d__a__t__a-i__s__s__u__e__s="${issuesIds.join(
      ',',
    )}">${textToBeReplaced}</s__p__a__n>` +
    strTranscript.substring(trueIndexEnd + currentSegmentSize);

  return strTranscript;
}

/**
 * Regular expression for basic email validation.
 */
export function isValidEmail(emailString: string): boolean {
  // Regex pattern for a single valid email.
  const singleEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Split the string by commas, and trim spaces.
  const emails = emailString.split(',').map(email => email.trim());

  // Check if every email in the list is valid.
  return emails.every(email => singleEmailPattern.test(email));
}

/**
 * Join the segments that the text is equals or less than 5 characters with the previous segment and build the transcript on each iteration.
 * Purpose: to avoid wrong index when searching on the timestamp string.
 * @param segments received BQ segments
 * @returns merged segments and the builded transcript.
 */
export function mergeSegmentsAndTranscript(segments: ISegment[]): { mergedSegments: ISegment[]; transcript: string } {
  const mergedSegments = [];
  const updatedSegments = [...segments];
  let transcript = '';

  for (let i = 0; i < updatedSegments.length; i++) {
    let currentSegment = { ...updatedSegments[i] };
    currentSegment.start = Math.floor(currentSegment.start);
    currentSegment.end = Math.floor(currentSegment.end);

    if (i === 0) currentSegment.text = currentSegment.text.trim();

    // Check if the current segment's text length is 5 or less and there's a previous segment
    if (currentSegment.text.trim().length <= 5 && mergedSegments.length > 0) {
      const lastSegment = mergedSegments[mergedSegments.length - 1];

      lastSegment.end = currentSegment.end;
      lastSegment.text += currentSegment.text;
      updatedSegments.splice(i, 1);
      i--;
    } else {
      mergedSegments.push(currentSegment);
    }

    transcript += currentSegment.text;
  }

  return { mergedSegments: mergedSegments, transcript: transcript.trim() };
}

// Function that removes all previous bold on transcript.
export function removePreviousBold() {
  let oldBolds = document.querySelectorAll('.bold');
  if (oldBolds && oldBolds.length > 0) {
    oldBolds.forEach(bold => {
      bold.className = bold.className.replace(' bold', '');
    });
  }
}

// Function that removes all previous selected spans.
export function removePreviousSelected() {
  let oldSelecteds = document.querySelectorAll('span.selected');
  if (oldSelecteds && oldSelecteds.length > 0) {
    oldSelecteds.forEach(selected => {
      selected.className = selected.className.replace(' selected', '');
    });
  }
}

// Find the player element and updates the current time.
export function updatePlayerCurrentTime(newTime: string) {
  let audioEl = document.querySelector('audio') as HTMLAudioElement;
  if (audioEl && audioEl.currentTime !== null) {
    audioEl.currentTime = parseInt(newTime);
  } else {
    let videoEl = document.querySelector('video') as HTMLVideoElement;
    if (videoEl && videoEl.currentTime != null) {
      videoEl.currentTime = parseInt(newTime);
    }
  }
}

export function isThereAtLeastOneHighlightThatIsNotInTheContext(context: string, issues: IssueHightlight[]): boolean {
  if (!issues || !context) {
    return false;
  }

  return issues.some((issue: IssueHightlight) => {
    return context.toLocaleLowerCase().lastIndexOf(issue.issueContent.toLocaleLowerCase()) === -1;
  });
}

/**
 * Returns the index of the selected text relative to the current context and the selected text.
 * @returns
 */
export function getSelectionIndex(selection: Selection) {
  if (!selection || !selection.rangeCount) {
    return { index: -1, selectedText: '' }; // No selection made
  }

  const range = selection.getRangeAt(0);
  const selectedText = range.toString();

  let parentElement =
    range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
      ? (range.commonAncestorContainer as HTMLElement)
      : range.commonAncestorContainer.parentElement;

  // If the selection happens inside a hightlight span
  // then consider the context div as parent
  // TODO: change hard-coded 'description' class
  if (
    parentElement?.className === 'description' &&
    parentElement?.tagName.toLowerCase() === 'span' &&
    parentElement?.parentElement?.className === 'context'
  ) {
    parentElement = parentElement?.parentElement;
  }

  if (!parentElement) {
    return { index: -1, selectedText: '' }; // Parent element not found
  }

  const preSelectionRange = document.createRange();
  preSelectionRange.selectNodeContents(parentElement);
  preSelectionRange.setEnd(range.startContainer, range.startOffset);

  const index = preSelectionRange.toString().length;

  return { index, selectedText };
}

export function updateHighlightsIndex(
  context: string,
  newContext: string,
  highlightsArray: IssueHightlight[],
  originalHighlightsArray: IssueHightlight[],
) {
  if (!context || !newContext || highlightsArray.length === 0) {
    return highlightsArray;
  }

  context = context.toLowerCase();
  newContext = newContext.toLowerCase();
  var offset = 0;

  // New context has entire old context on it, just need to add offset
  if (newContext.indexOf(context) > -1) {
    offset = newContext.indexOf(context);
  }
  // Old context has entire new context on it, just need to subtract offset
  else if (context.indexOf(newContext) > -1) {
    offset = context.indexOf(newContext) * -1;
  } else {
    const longestHighlight = highlightsArray.reduce((max, highlight) => {
      return highlight.issueContent.length > max.issueContent.length ? highlight : max;
    }, highlightsArray[0]);

    const lowerCaseLongestHighlight = {
      ...longestHighlight,
      issueContent: longestHighlight.issueContent.toLowerCase(),
    };

    const highlightIndexOnNewContext = newContext.indexOf(lowerCaseLongestHighlight.issueContent);
    const highlightIndexOnContext = context.indexOf(lowerCaseLongestHighlight.issueContent);

    // If there's two occurances of the longest hightlight
    // with the current logic we can't be sure we're matching the correct one
    // so we remove all highligthts
    if (
      highlightIndexOnNewContext !== newContext.lastIndexOf(lowerCaseLongestHighlight.issueContent) ||
      highlightIndexOnContext !== context.lastIndexOf(lowerCaseLongestHighlight.issueContent)
    ) {
      return [];
    }

    offset = highlightIndexOnContext - highlightIndexOnNewContext;

    var test = newContext.substring(
      longestHighlight.issueIndex + offset,
      longestHighlight.issueIndex + offset + longestHighlight.issueContent.length,
    );
    if (test != lowerCaseLongestHighlight.issueContent) {
      // TODO: Figure out logic to have this right in the first place and don't need to test it like this
      offset = offset * -1;
    }
  }

  return offset === 0
    ? highlightsArray
    : highlightsArray
        .map((highlight: IssueHightlight) => ({
          ...highlight,
          issueIndex: highlight.issueIndex + offset,
          indexEnd: highlight.issueIndex + offset + highlight.issueContent.length,
        }))
        .filter(
          (h: IssueHightlight) => h.issueContent.toLowerCase() === newContext.substring(h.issueIndex, h.indexEnd),
        );
}

/**
 * Mark a section on transcript by time.
 * @param timeStart
 */
export function selectTranscriptSection(timeStart: number) {
  const firstSection = document.querySelector(`#spnKeyWord_${timeStart}.none`);
  if (firstSection) {
    removePreviousSelected();
    if (!firstSection.className.includes('selected')) {
      firstSection.className = firstSection.className + ' selected';
    }
  }

  updatePlayerCurrentTime(timeStart.toString());
}

// Removes highlights that overlaps with others.
export function removeOverlapping(sortedIssues: IssueHightlight[]) {
  if (sortedIssues.length === 0) return sortedIssues;

  sortedIssues = sortedIssues.map(issue => ({
    ...issue,
    indexEnd: issue.issueIndex + issue.issueContent.length,
  }));

  sortedIssues = sortedIssues.map((issue, i) => {
    const issueIncludedInAnother = sortedIssues!.filter(
      (aIssue, j) =>
        i != j &&
        ((issue.issueIndex > aIssue.issueIndex && issue.issueIndex < aIssue.indexEnd!) ||
          (issue.indexEnd! > aIssue.issueIndex && issue.indexEnd! < aIssue.indexEnd!) ||
          (issue.indexEnd == aIssue.indexEnd! && issue.issueIndex == aIssue.issueIndex)),
    );

    if (issueIncludedInAnother != null && issueIncludedInAnother.length) {
      const overlappingIssue = issueIncludedInAnother[0];
      if (issue.issueIndex >= overlappingIssue.issueIndex && issue.issueIndex <= overlappingIssue.indexEnd!) {
        issue.issueContent = issue.issueContent.slice(overlappingIssue.indexEnd! - issue.issueIndex);
        issue.issueIndex = overlappingIssue.indexEnd!;
      } else if (issue.indexEnd! >= overlappingIssue.issueIndex && issue.indexEnd! <= overlappingIssue.indexEnd!) {
        issue.issueContent = issue.issueContent.slice(0, overlappingIssue.issueIndex - issue.issueIndex);
        issue.issueIndex = overlappingIssue.issueIndex!;
      }
    }

    return issue;
  });

  sortedIssues = sortedIssues.filter((issue: IssueHightlight) => issue.issueContent !== '');

  return sortedIssues;
}

export function formatDate(dateString: string) {
  const date = parseISO(dateString);
  return format(date, 'MMM dd, yyyy - hh:mm:ss a');
}

export function highlightWithYellowBackground(content: string): string {
  return `<span style="background: #FFFF00;">${content}</span>`;
}

export function processHighlightedIssues(sr: SubmissionIssueDTO): string {
  let srIssues = sr.reviewIssue.issues ? [...sr.reviewIssue.issues] : [];
  let newIssueContext = sr.reviewIssue.issueContext;

  srIssues.sort((a: any, b: any) => b.issueIndex - a.issueIndex);
  srIssues = removeOverlapping(srIssues);

  srIssues.forEach((issue: any) => {
    if (issue.issueContent) {
      newIssueContext = replaceContextWithHighlightByIndex(newIssueContext, issue.issueContent, issue.issueIndex);
    }
  });

  if (!srIssues || (srIssues && srIssues.length === 0)) {
    newIssueContext = replaceContextWithHighlight(newIssueContext, sr.reviewIssue.issueContent);
  }

  return newIssueContext;
}

export function replaceContextWithHighlightByIndex(issueContext: string, content: string, startIndex: number) {
  issueContext =
    issueContext.substring(0, startIndex) +
    highlightWithYellowBackground(content) +
    issueContext.substring(startIndex + content.length);
  return issueContext;
}

export function replaceContextWithHighlight(issueContext: string, content: string): string {
  const scapedContent = escapeRegExp(content);
  const regex = new RegExp(`(${scapedContent})`, 'gi');

  return issueContext.replace(regex, (_, p1) => highlightWithYellowBackground(p1));
}
