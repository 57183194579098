import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { Assignee } from '../../interface/assignee';

interface DashboardState {
  assignees: Array<Assignee>;
}

const initialState: DashboardState = {
  assignees: [],
};

const dashboardSlice: Slice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setAssignees: (state: DashboardState, action: PayloadAction<Array<Assignee>>) => {
      state.assignees = action.payload;
    },
  },
});

export const { setAssignees } = dashboardSlice.actions;

export default dashboardSlice.reducer;
