import { configureStore } from '@reduxjs/toolkit';
import submissionReducer from '../reducers/submissionReducer';
import transcriptReducer from '../reducers/transcriptReducer';
import ruleViolationModalReducer from '../reducers/ruleViolationModalReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import reviewReducer from '../reducers/reviewReducer';

export const store = configureStore({
  reducer: {
    submission: submissionReducer,
    transcript: transcriptReducer,
    ruleViolationModal: ruleViolationModalReducer,
    dashboard: dashboardReducer,
    review: reviewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
