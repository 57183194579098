import { DateTime } from 'luxon';

export const tableCols = [
  {
    label: 'Apply Code',
    sort: 'apply_code',
    width: '110px',
  },
  {
    label: 'Entry Date',
    sort: 'entry_date',
    width: '120px',
  },
  {
    label: 'Assigned To	',
    sort: 'assigned_to_name',
    width: '160px',
  },
  {
    label: 'Type',
    sort: 'type_name',
    width: '90px',
  },
  {
    label: 'Title',
    sort: 'title_english',
    className: 'title',
  },
  {
    label: 'Submitted By',
    sort: 'submitter_name',
    width: '160px',
  },
  {
    label: 'SKU',
    sort: 'sku',
    width: '130px',
  },
  {
    label: 'Speaker',
    sort: 'speaker_full_name',
    width: '140px',
  },
  {
    label: 'Status',
    sort: 'form_status',
    width: '120px',
  },
  {
    label: 'Companion result',
    sort: 'model_decision',
    width: '150px',
  },
];

export function formatSourceDate(source_date: any) {
  if (!(source_date && source_date)) return '';
  const sourceDate = new Date(source_date);
  return DateTime.fromJSDate(sourceDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd');
}

export function formatDateToSource(source_date: any) {
  if (!(source_date && source_date)) return '';
  const sourceDate = new Date(source_date);
  return DateTime.fromJSDate(sourceDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd HH:mm:ss');
}
