import Select, { ActionMeta, SingleValue } from 'react-select';
import './custom-language-select.scss';
import { useAppSelector } from '../../../../redux/hooks';

export interface CustomOptionType {
  label: string;
  value: string;
  languageCode: string;
}

export const selectDefaultOption = { value: '1', label: 'English', languageCode: 'en-us' };

const customOption = (option: CustomOptionType) => (
  <div className="opt">
    {option.languageCode !== '' ? <span className={`lang-icon lang-icon-${option.languageCode}`}></span> : <></>}
    {option.label}
  </div>
);

interface CustomLanguageSelectProps {
  options: CustomOptionType[];
  isDisabled: boolean;
  handleChange: (newValue: SingleValue<CustomOptionType>, actionMeta: ActionMeta<CustomOptionType>) => void;
}

export default function CustomLanguageSelect({ options, isDisabled, handleChange }: CustomLanguageSelectProps) {
  const selectedLanguage: CustomOptionType = useAppSelector(state => state.transcript.selectedLanguage);

  return (
    <Select
      className="custom-language-select"
      value={selectedLanguage}
      onChange={handleChange}
      options={options}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.value}
      formatOptionLabel={customOption}
      isDisabled={isDisabled}
      isSearchable={false}
    />
  );
}
